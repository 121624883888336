import { DialogContent as MuiDialogContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataPermissionTypeDataEnum,
  PersonData,
  TouchpointStatusTypeDataEnum,
} from '@ysura/common';
import { add } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ActionDialog } from '@/components/ActionDialog';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import {
  useAddActivityMutation,
  useGetActivityTypeListQuery,
} from '@/services/graphql/hooks';
import { parseActivityTypeConnection } from '@/services/graphql/parsers';
import { TopicsCard } from '@/views/Activity/Create/Topics/TopicsCard';
import { TopicsCardSkeleton } from '@/views/Activity/Create/Topics/TopicsCardSkeleton';
import { DateFields } from '@/views/Activity/TouchpointManagement/TouchpointType/TouchpointDateAndTime';

import { AttendeeColumn, AttendeeColumnSkeleton } from './Attendee';
import { encodeOid, prepareDataForAddActivityMutation } from './helper';
import {
  TouchpointTypeColumn,
  TouchpointTypeColumnSkeleton,
} from './TouchpointType';

type CreateActivityDialogProps = {
  isOpen: boolean;
  selectedAttendee?: PersonData;
  onClose: VoidFunction;
};

export const CreateActivityDialog = ({
  isOpen,
  onClose,
  selectedAttendee,
}: CreateActivityDialogProps) => {
  const [dialogHeight, setDialogHeight] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addActivityMutation, { loading }] = useAddActivityMutation();
  const {
    data,
    loading: isQueryLoading,
    error: queryError,
  } = useGetActivityTypeListQuery({
    ...errorConfigBannerDialog,
    skip: !isOpen,
  });

  const dialogRef = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setDialogHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const parsedActivityTypeList = data?.activityTypes
    ? parseActivityTypeConnection(data.activityTypes).filter(
        (item) =>
          item?.permissions?.includes(
            DataPermissionTypeDataEnum.ASSIGN_ACTIVITY_TYPE
          )
      )
    : [];

  const validationSchema = Yup.object({
    touchpointType: Yup.object().shape({
      oid: Yup.string().required(),
    }),
    attendees: Yup.array().min(1).required(),
    topics: Yup.array().min(1).required(),
  });

  const {
    handleSubmit,
    getFieldProps,
    isValid: isFormValid,
    isSubmitting,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    validationSchema,
    initialValues: {
      // activity column
      touchpointType: null,
      startDate: null,
      startDateTime: null,
      endDate: null,
      endDateTime: null,
      touchpointStatus: TouchpointStatusTypeDataEnum.PlannedTouchpoint,
      // attendee column
      attendees: [],
      organization: null,
      // topics column
      topics: [],
    },
    onSubmit: async (values) => {
      const activityForMutation = prepareDataForAddActivityMutation(values);

      await addActivityMutation({
        ...errorConfigBannerDialog,
        variables: {
          organizationId: activityForMutation.organizationId,
          attendees: activityForMutation.attendees,
          startDate: activityForMutation.startDate,
          endDate: activityForMutation.endDate,
          discussedTopics: activityForMutation.discussedTopics,
          activityTypeId: activityForMutation.activityTypeId,
          status: activityForMutation.status,
          confirmed: activityForMutation.confirmed,
        },
        onCompleted: ({ addActivity }) => {
          const encodedOid = addActivity && encodeOid(addActivity.oid);
          if (encodedOid) {
            resetForm();
            navigate(`/touchpoint/${encodedOid}`);
          }
        },
      });
    },
  });

  const handleCloseCreateTouchpointDialog = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    // sync the form if we're opening the dialog via a person page
    if (selectedAttendee && isOpen) {
      setFieldValue('attendees', [selectedAttendee]);
      setFieldValue(
        'organization',
        selectedAttendee.employments?.[0].organization
      );
    }
  }, [setFieldValue, selectedAttendee, isOpen]);

  const isPrimaryButtonDisabled =
    isQueryLoading || !isFormValid || isSubmitting || Boolean(queryError);

  // Initialize the dates on first time when dialog is opened
  if (!values?.startDate && !values?.startDateTime) {
    const minimumAllowedDuration = 15;
    const date = new Date();
    setFieldValue?.(DateFields.StartDateTime, date.toISOString());
    setFieldValue?.(
      DateFields.EndDateTime,
      add(date, { minutes: minimumAllowedDuration }).toISOString()
    );
    setFieldValue?.(DateFields.StartDate, date.toISOString().slice(0, 10));
    setFieldValue?.(DateFields.EndDate, date.toISOString().slice(0, 10));
  }

  return (
    <ActionDialog
      maxWidth={'xl'}
      isOpen={isOpen}
      isLoading={loading}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      title={t('components.touchpointManagement.createTouchpoint')}
      primaryButtonText={t('components.common.create')}
      secondaryButtonText={t('components.common.cancel')}
      onClickPrimaryButton={handleSubmit}
      onClickSecondaryButton={handleCloseCreateTouchpointDialog}
    >
      <DialogContent ref={dialogRef}>
        <StyledGrid container spacing={{ md: 3, xs: 2 }}>
          <Grid item md={4} xs={12}>
            {isQueryLoading ? (
              <TouchpointTypeColumnSkeleton />
            ) : (
              <TouchpointTypeColumn
                mode="create"
                setFieldValue={setFieldValue}
                activityTypes={parsedActivityTypeList}
                touchpointValues={values}
                isSubmitting={isSubmitting}
              />
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            {isQueryLoading ? (
              <AttendeeColumnSkeleton />
            ) : (
              <AttendeeColumn
                mode="create"
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                touchpointValues={values}
                dialogHeight={dialogHeight}
                isSubmitting={isSubmitting}
              />
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            {isQueryLoading ? (
              <TopicsCardSkeleton />
            ) : (
              <TopicsCard
                setFieldValue={setFieldValue}
                touchpointValues={values}
                activityTypes={parsedActivityTypeList}
                isSubmitting={isSubmitting}
              />
            )}
          </Grid>
        </StyledGrid>
      </DialogContent>
    </ActionDialog>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  paddingTop: 0,
  height: '80vh',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    overflow: 'auto',
    padding: 0,
    height: '65vh',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    direction: 'column',
    paddingBottom: theme.spacing(4),
  },
}));
