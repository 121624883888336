import {
  Box,
  DialogContent as MuiDialogContent,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizerPresentationStateChangePayload, Page } from '@ysura/common';
import { useEffect, useRef, useState } from 'react';

import { HTMLFileActions } from '@/components/DocumentPreview/HTMLFileActions';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { PointerContainer } from '@/views/Interaction/Remote/Room/MediaSharing/PointerContainer';

type HTMLFileProps = {
  data: string | undefined;
  initialPage?: Page;
  onClose: VoidFunction;
  isInInteraction?: boolean;
  isFullScreenShown?: boolean;
  isReadOnlyMode?: boolean;
};

export const HTMLFile = ({
  data,
  initialPage = { indexh: 0, indexv: 0 },
  onClose,
  isInInteraction = false,
  isFullScreenShown = false,
  isReadOnlyMode = false,
}: HTMLFileProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(initialPage);
  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  const { changePage, onMediaStateChanged, broadcastHTMLEvent } =
    useInteraction();

  const handleHTMLPageTransition = (offset: number) => {
    const nextPage = { indexh: currentPageNumber.indexh + offset, indexv: 0 };

    if (nextPage.indexh >= 0 && nextPage.indexh !== currentPageNumber.indexh) {
      const message = JSON.stringify({
        method: 'slide',
        args: [nextPage.indexh, nextPage.indexv],
      });
      // nosemgrep: javascript.browser.security.wildcard-postmessage-configuration.wildcard-postmessage-configuration
      iframeRef.current?.contentWindow?.postMessage(message, '*');
    }
  };

  const [startTimeInPage, setStartTimeInPage] = useState<Date>(new Date());

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent) => {
      const eventData = event.data ?? null;
      if (!eventData && eventData === 'unchanged') {
        return;
      }

      try {
        const revealEvent = JSON.parse(eventData);

        // Page changes need to be treated separately as we need to track the time spent in each page
        if (revealEvent.eventName === 'slidechanged') {
          const nextPage = {
            indexh: revealEvent.state.indexh,
            indexv: revealEvent.state.indexv,
          };
          if (isInInteraction) {
            changePage(
              nextPage,
              currentPageNumber,
              new Date().getTime() - startTimeInPage?.getTime()
            );

            setStartTimeInPage(new Date());
          }

          setCurrentPageNumber(nextPage);
        }
        // All other events are broadcasted to the state-server ...
        else if (
          revealEvent.eventName === 'fragmentshown' ||
          revealEvent.eventName === 'interaction'
        ) {
          broadcastHTMLEvent(revealEvent);
        }
        // ... except for the total number of slides callback.
        else if (
          revealEvent.eventName === 'callback' &&
          revealEvent.method === 'getTotalSlides'
        ) {
          setTotalSlides(revealEvent.result); // = the total number of slides
        }
      } catch (e) {
        /* empty, malformed event or event that we do not cover here. */
      }
    };

    window.addEventListener('message', handleWindowMessage);

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [
    changePage,
    startTimeInPage,
    currentPageNumber,
    isInInteraction,
    broadcastHTMLEvent,
  ]);

  useEffect(() => {
    const handlePresentationStateChanged = (
      data: OrganizerPresentationStateChangePayload
    ) => {
      if (
        data.type === 'reveal' &&
        data.stateChange.namespace === 'reveal' &&
        (data.stateChange.eventName === 'slidechanged' ||
          data.stateChange.eventName === 'fragmentshown') &&
        isReadOnlyMode
      ) {
        const { indexh, indexv, indexf } = data.stateChange.state;
        const message = JSON.stringify({
          method: 'slide',
          args: [indexh, indexv, indexf],
        });
        iframeRef?.current?.contentWindow?.postMessage(message, '*');
      }
    };

    const unsubscribeOnMediaStateChanged = onMediaStateChanged?.(
      handlePresentationStateChanged
    );

    return () => {
      if (unsubscribeOnMediaStateChanged) {
        unsubscribeOnMediaStateChanged();
      }
    };
  }, [onMediaStateChanged, isReadOnlyMode]);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (data && iframe) {
      const doc = iframe?.contentWindow?.document;
      doc?.open();
      doc?.write(data);
      doc?.close();
    }
  }, [data]);

  const renderIframe = () => {
    return (
      <Iframe
        ref={iframeRef}
        title="HTML"
        isReadOnlyMode={isReadOnlyMode}
        data-testid="HTML_iframe"
        onLoad={() => {
          const totalOfSections =
            iframeRef.current?.contentDocument?.querySelectorAll(
              'div > section'
            ).length ?? 0;

          window.parent.postMessage(
            JSON.stringify({
              namespace: 'reveal',
              eventName: 'callback',
              method: 'getTotalSlides',
              result: totalOfSections,
            }),
            '*'
          );

          // Change to the initial page
          const { indexh, indexv, indexf } = initialPage;
          const message = JSON.stringify({
            method: 'slide',
            args: [indexh, indexv, indexf],
          });
          iframeRef.current?.contentWindow?.postMessage(message, '*');

          // Add event listener to handle click events.
          // This is not handled by reveal.js but we piggyback on the reveal namespace
          iframeRef.current?.contentWindow?.addEventListener(
            'message',
            (event) => {
              const { data } = event;
              const isHandledEvent = data && data.namespace === 'reveal';
              if (isHandledEvent) {
                if (data.eventName === 'interaction' && data.interaction) {
                  const { type, elementId, selector } = event.data.interaction;
                  if (type === 'click') {
                    if (typeof elementId === 'string') {
                      iframeRef.current?.contentDocument
                        ?.getElementById(elementId)
                        ?.click();
                    } else if (typeof selector === 'string') {
                      iframeRef.current?.contentDocument
                        ?.querySelector<HTMLElement>(selector)
                        ?.click();
                    }
                  }
                }
              }
            }
          );
        }}
      />
    );
  };

  const renderActionButtons = () => {
    return (
      <HTMLFileActions
        currentPageNumber={currentPageNumber.indexh + 1}
        totalPages={totalSlides}
        isFullScreenShown={isFullScreenShown}
        isReadOnlyMode={isReadOnlyMode}
        onHTMLPageTransition={handleHTMLPageTransition}
        onClose={onClose}
      />
    );
  };

  return (
    <Wrapper>
      <DialogContent>
        {isInInteraction ? (
          <PointerContainer iframeRef={iframeRef}>
            {renderIframe()}
          </PointerContainer>
        ) : (
          renderIframe()
        )}

        {isSmallScreenLandscape && renderActionButtons()}
      </DialogContent>

      {!isSmallScreenLandscape && renderActionButtons()}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  padding: theme.spacing(2),
}));

const Iframe = styled('iframe', {
  shouldForwardProp: (prop) => prop !== 'isReadOnlyMode',
})<{ isReadOnlyMode: boolean }>(({ theme, isReadOnlyMode }) => ({
  width: '100%',
  height: '100%',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  pointerEvents: isReadOnlyMode ? 'none' : 'auto',
}));
