import {
  ConsentCollectState,
  ConsentData,
  ConsentViewerPayload,
  CountryData,
  getFormikInitialValues,
  Page,
  PresentationStateChange,
  RejectConsentPayload,
  RevokeConsentPayload,
  SampleRequestViewerPayload,
  SupportedMediaTypeDataEnum,
} from '@ysura/common';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ConsentBeingShared,
  DEFAULT_FN,
  MediaBeingShared,
  MediaSharedForCoOrganizer,
  SetState,
} from './useInteractionTypes';
import { useStateServer } from './useStateServer';

export type StartSharingConsentProps = {
  consent: ConsentData;
  validation?: {
    defaultCountryPhone?: CountryData;
  };
};

type UseProcessState = {
  isAnyProcessShared: boolean;
  isSharingInFullScreen: boolean;
  setIsSharingInFullScreen: SetState<boolean>;
  // Media
  mediaSharedByOrganizer: MediaBeingShared | null;
  setMediaSharedByOrganizer: (props: MediaBeingShared | null) => void;
  mediaSharedForCoOrganizer: MediaSharedForCoOrganizer | null;
  setMediaSharedForCoOrganizer: SetState<MediaSharedForCoOrganizer | null>;
  broadcastPageChange: (nextPage: Page) => void;
  broadcastHTMLEvent: (stateChange: PresentationStateChange) => void;
  // Consent
  consentSharedByOrganizer: ConsentBeingShared | null;
  setConsentSharedByOrganizer: (props: ConsentBeingShared | null) => void;
  consentSharedForCoOrganizer: ConsentViewerPayload | null;
  setConsentSharedForCoOrganizer: SetState<ConsentViewerPayload | null>;
  startSharingConsent: (props: StartSharingConsentProps) => void;
  stopSharingConsent: VoidFunction;
  changeSharingConsent: (payload: ConsentViewerPayload) => void;
  setCurrentConsentValues: (
    consentValues: ConsentBeingShared['currentConsentValues']
  ) => void;
  setCurrentConsentSignature: (signature: string) => void;
  setCurrentConsentPreview: (preview: string) => void;
  setCurrentConsentStep: (step: ConsentBeingShared['currentStep']) => void;
  rejectSharedConsent: (payload: RejectConsentPayload) => void;
  revokeSharedConsent: (payload: RevokeConsentPayload) => void;
  // ProMatCollection
  startSampleRequestCollection: (data: SampleRequestViewerPayload) => void;
  stopSampleRequestCollection: (id: string) => void;
  changeSampleRequestCollection: (payload: SampleRequestViewerPayload) => void;
  currentSampleRequest: SampleRequestViewerPayload | null;
  setCurrentSampleRequest: SetState<SampleRequestViewerPayload | null>;
};

export const useProcessStateInitialState: UseProcessState = {
  isAnyProcessShared: false,
  // Media
  mediaSharedByOrganizer: null,
  setMediaSharedByOrganizer: DEFAULT_FN,
  mediaSharedForCoOrganizer: null,
  setMediaSharedForCoOrganizer: DEFAULT_FN,
  isSharingInFullScreen: false,
  setIsSharingInFullScreen: DEFAULT_FN,
  broadcastPageChange: DEFAULT_FN,
  broadcastHTMLEvent: DEFAULT_FN,
  // Consent
  consentSharedByOrganizer: null,
  setConsentSharedByOrganizer: DEFAULT_FN,
  consentSharedForCoOrganizer: null,
  setConsentSharedForCoOrganizer: DEFAULT_FN,
  startSharingConsent: DEFAULT_FN,
  stopSharingConsent: DEFAULT_FN,
  changeSharingConsent: DEFAULT_FN,
  setCurrentConsentValues: DEFAULT_FN,
  setCurrentConsentSignature: DEFAULT_FN,
  setCurrentConsentPreview: DEFAULT_FN,
  setCurrentConsentStep: DEFAULT_FN,
  rejectSharedConsent: DEFAULT_FN,
  revokeSharedConsent: DEFAULT_FN,
  // ProMatCollection
  startSampleRequestCollection: DEFAULT_FN,
  stopSampleRequestCollection: DEFAULT_FN,
  changeSampleRequestCollection: DEFAULT_FN,
  currentSampleRequest: null,
  setCurrentSampleRequest: DEFAULT_FN,
};

const ProcessStateContext = createContext<UseProcessState>(
  useProcessStateInitialState
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const ProcessStateContextProvider = (props) => {
  // Media
  const [mediaSharedByOrganizer, setMediaSharedByOrganizer] =
    useState<MediaBeingShared | null>(null);
  const [mediaSharedForCoOrganizer, setMediaSharedForCoOrganizer] =
    useState<MediaSharedForCoOrganizer | null>(null);

  // Consent
  const [consentSharedByOrganizer, setConsentSharedByOrganizer] =
    useState<ConsentBeingShared | null>(null);
  const [consentSharedForCoOrganizer, setConsentSharedForCoOrganizer] =
    useState<ConsentViewerPayload | null>(null);

  // Promats
  const [currentSampleRequest, setCurrentSampleRequest] =
    useState<SampleRequestViewerPayload | null>(null);

  const [isSharingInFullScreen, setIsSharingInFullScreen] = useState(false);

  const {
    broadcastOrganizerPresentationStateChange,
    broadcastConsentClose,
    broadcastConsentFormChange,
    broadcastConsentFormOpen,
    broadcastSampleRequestOpen,
    broadcastSampleRequestClose,
    broadcastSampleRequestChange,
    broadcastRejectConsent,
    broadcastRevokeConsent,
  } = useStateServer();

  /*******************
   * Media           *
   *******************/
  const broadcastPageChange = useCallback(
    (nextPage: Page) => {
      if (mediaSharedByOrganizer?.media) {
        const mediaType = mediaSharedByOrganizer?.media?.mediaType;

        if (mediaType === SupportedMediaTypeDataEnum.PDF) {
          broadcastOrganizerPresentationStateChange({
            mediaId: mediaSharedByOrganizer?.media?.oid ?? '',
            type: 'pdf',
            stateChange: {
              namespace: 'pdfViewer',
              state: {
                page: nextPage.indexh,
              },
            },
          });
        } else if (mediaType === SupportedMediaTypeDataEnum.HTML) {
          broadcastOrganizerPresentationStateChange({
            mediaId: mediaSharedByOrganizer?.media?.oid ?? '',
            type: 'reveal',
            stateChange: {
              eventName: 'slidechanged',
              namespace: 'reveal',
              state: {
                ...nextPage,
                paused: false,
                overview: false,
              },
            },
          });
        }
      }
    },
    [broadcastOrganizerPresentationStateChange, mediaSharedByOrganizer?.media]
  );

  const broadcastHTMLEvent = useCallback(
    (stateChange: PresentationStateChange) => {
      broadcastOrganizerPresentationStateChange({
        mediaId: mediaSharedByOrganizer?.media?.oid ?? '',
        type: 'reveal',
        stateChange,
      });
    },
    [
      broadcastOrganizerPresentationStateChange,
      mediaSharedByOrganizer?.media?.oid,
    ]
  );

  /******************
   * Consent        *
   ******************/
  const startSharingConsent = useCallback(
    ({ consent, validation }: StartSharingConsentProps) => {
      if (!consent?.consentType) {
        return;
      }

      const consentType = consent.consentType;
      const consentCommunicationData = consentType?.consentItems ?? [];
      const initialConsentValues = getFormikInitialValues(
        consentCommunicationData,
        null
      );

      const state: ConsentCollectState = {
        step: 'collect',
        title: consentType?.name ?? '',
        staticText: consentType?.description ?? '',
        consentItems: initialConsentValues,
        consentCommunicationData: consentType?.consentItems ?? [],
        signature: '',
      };

      const documentId = consentType?.digitalSignatureTemplate?.document?.id;

      const payload: ConsentViewerPayload = {
        id: documentId ?? '',
        step: 'collect',
        consentType,
        validation,
        state,
      };

      setConsentSharedByOrganizer({
        oid: consent.oid,
        consentType,
        currentConsentValues: initialConsentValues,
        currentSignature: '',
        currentPreview: '',
        currentStep: 'collect',
        initialState: payload,
      });

      broadcastConsentFormOpen(payload);
    },
    [broadcastConsentFormOpen]
  );

  const rejectSharedConsent = useCallback(
    (payload: RejectConsentPayload) => {
      broadcastRejectConsent(payload);
    },
    [broadcastRejectConsent]
  );

  const revokeSharedConsent = useCallback(
    (payload: RevokeConsentPayload) => {
      broadcastRevokeConsent(payload);
    },
    [broadcastRevokeConsent]
  );

  const stopSharingConsent = useCallback(() => {
    setConsentSharedByOrganizer((currentConsent) => {
      if (!currentConsent?.consentType) {
        return null;
      }

      const documentId =
        currentConsent.consentType?.digitalSignatureTemplate?.document?.id;

      if (documentId) {
        broadcastConsentClose({ id: documentId });
      }

      return null;
    });
  }, [broadcastConsentClose]);

  const changeSharingConsent = useCallback(
    (payload: ConsentViewerPayload) => {
      broadcastConsentFormChange(payload);
    },
    [broadcastConsentFormChange]
  );

  const setCurrentConsentValues = useCallback(
    (consentValues: ConsentBeingShared['currentConsentValues']) => {
      setConsentSharedByOrganizer((currentConsent) => {
        if (!currentConsent) {
          return null;
        }

        return {
          ...currentConsent,
          currentConsentValues: consentValues,
        };
      });
    },
    []
  );

  const setCurrentConsentSignature = useCallback((signature: string) => {
    setConsentSharedByOrganizer((currentConsent) => {
      if (!currentConsent) {
        return null;
      }

      return {
        ...currentConsent,
        currentSignature: signature,
      };
    });
  }, []);

  const setCurrentConsentPreview = useCallback((preview: string) => {
    setConsentSharedByOrganizer((currentConsent) => {
      if (!currentConsent) {
        return null;
      }

      return {
        ...currentConsent,
        currentPreview: preview,
      };
    });
  }, []);

  const setCurrentConsentStep = useCallback(
    (step: ConsentBeingShared['currentStep']) => {
      setConsentSharedByOrganizer((currentConsent) => {
        if (!currentConsent) {
          return null;
        }

        return {
          ...currentConsent,
          currentStep: step,
        };
      });
    },
    []
  );

  /**************************
   * Sample Request         *
   **************************/
  const startSampleRequestCollection = useCallback(
    (data: SampleRequestViewerPayload) => {
      broadcastSampleRequestOpen(data);
    },
    [broadcastSampleRequestOpen]
  );

  const stopSampleRequestCollection = useCallback(
    (id: string) => {
      broadcastSampleRequestClose({ id: id });
      setCurrentSampleRequest(null);
    },
    [broadcastSampleRequestClose]
  );

  const changeSampleRequestCollection = useCallback(
    (payload: SampleRequestViewerPayload) => {
      broadcastSampleRequestChange(payload);
    },
    [broadcastSampleRequestChange]
  );

  const values = useMemo(
    () => ({
      isAnyProcessShared:
        !!mediaSharedByOrganizer ||
        !!consentSharedByOrganizer ||
        !!currentSampleRequest,
      // Media
      mediaSharedByOrganizer,
      setMediaSharedByOrganizer,
      mediaSharedForCoOrganizer,
      setMediaSharedForCoOrganizer,
      isSharingInFullScreen,
      setIsSharingInFullScreen,
      broadcastPageChange,
      broadcastHTMLEvent,
      // Consent
      consentSharedByOrganizer,
      setConsentSharedByOrganizer,
      consentSharedForCoOrganizer,
      setConsentSharedForCoOrganizer,
      startSharingConsent,
      stopSharingConsent,
      changeSharingConsent,
      setCurrentConsentValues,
      setCurrentConsentSignature,
      setCurrentConsentPreview,
      setCurrentConsentStep,
      rejectSharedConsent,
      revokeSharedConsent,
      // ProMatCollection
      startSampleRequestCollection,
      stopSampleRequestCollection,
      changeSampleRequestCollection,
      currentSampleRequest,
      setCurrentSampleRequest,
    }),
    [
      broadcastHTMLEvent,
      broadcastPageChange,
      changeSampleRequestCollection,
      changeSharingConsent,
      consentSharedByOrganizer,
      consentSharedForCoOrganizer,
      isSharingInFullScreen,
      mediaSharedByOrganizer,
      mediaSharedForCoOrganizer,
      setCurrentConsentPreview,
      setCurrentConsentSignature,
      setCurrentConsentStep,
      setCurrentConsentValues,
      startSampleRequestCollection,
      startSharingConsent,
      stopSampleRequestCollection,
      stopSharingConsent,
      rejectSharedConsent,
      revokeSharedConsent,
      currentSampleRequest,
      setCurrentSampleRequest,
    ]
  );

  return <ProcessStateContext.Provider value={values} {...props} />;
};

export const useProcessState = (): UseProcessState => {
  return useContext(ProcessStateContext);
};
