import {
  Button as MuiButton,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type InteractionOverviewCardProps = {
  title: string;
  isInteractionDone?: boolean;
  viewAllHandler?: VoidFunction;
  children?: ReactElement;
};

export const InteractionOverviewColumn: FC<InteractionOverviewCardProps> = ({
  title,
  viewAllHandler,
  isInteractionDone = false,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper isInteractionDone={isInteractionDone}>
      <StyledCardHeader
        title={title}
        titleTypographyProps={{
          variant: 'subtitle2',
        }}
        action={
          !isInteractionDone &&
          viewAllHandler && (
            <Button variant="text" onClick={viewAllHandler}>
              {t('pages.interaction.common.viewAll')}
            </Button>
          )
        }
      />

      <StyledCardContent>{children}</StyledCardContent>
    </Wrapper>
  );
};

type InteractionDoneProps = {
  isInteractionDone: boolean;
};

const Wrapper = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isInteractionDone',
})<InteractionDoneProps>(({ theme, isInteractionDone }) => ({
  backgroundColor: isInteractionDone
    ? theme.palette.background.paper
    : theme.palette.transparentWhite[8],
  border: `1px solid ${theme.palette.transparentWhite[16]}`,
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
  paddingTop: 0,
  paddingBottom: 0,
});
