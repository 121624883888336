import {
  Callback,
  ClientToServerEvents,
  ConsentViewerClosePayload,
  ConsentViewerPayload,
  convertMediaDataToMediaEvent,
  DataCallback,
  EmitEventNames,
  EmitEventPayloads,
  EventSubscription,
  handleRoomReplayEvents,
  HeartbeatCallbackParams,
  Media,
  MediaData,
  MultiEventSubscription,
  OnEventNames,
  OrganizerPresentationStateChangePayload,
  Participant,
  Pointer,
  RejectConsentPayload,
  RevokeConsentPayload,
  Room,
  RoomJoinCallbackParams,
  RoomParticipantPayload,
  RoomPayload,
  SampleRequestViewerClosePayload,
  SampleRequestViewerPayload,
  ScreenShareStartPayload,
  ServerToClientEvents,
  unpackNodeId,
} from '@ysura/common';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { io, Socket } from 'socket.io-client';

import { useCurrentUser, useNotification } from '@/hooks';
import { getPersonFullNameForInteraction } from '@/services/helper';
import { getRealm, getStateServerUrl } from '@/utils/hostUtils';

import {
  DEFAULT_CALLBACK,
  DEFAULT_FN,
  InitChannelArgs,
  SetState,
} from './useInteractionTypes';

export interface StateServerProviderInterface {
  isStateServerInitialized: boolean;
  roomInfo?: Room;
  setRoomInfo: SetState<Room | undefined>;
  totalPeopleInRoom: number;
  initChannel: ({ roomId, role }: InitChannelArgs) => void;
  doHeartbeat: (
    participant: Participant,
    callback: DataCallback<HeartbeatCallbackParams>
  ) => void;
  onAttendeeReadyOrganizerNotify: (callback: Callback) => Callback;
  broadcastSampleRequestOpen: (event: SampleRequestViewerPayload) => void;
  broadcastSampleRequestChange: (event: SampleRequestViewerPayload) => void;
  broadcastSampleRequestClose: (event: SampleRequestViewerClosePayload) => void;
  onSampleRequestValueChanged: (
    callback: DataCallback<SampleRequestViewerPayload>
  ) => Callback;
  onSampleRequestAttendeeSubmitted: (
    callback: DataCallback<SampleRequestViewerPayload>
  ) => Callback;
  broadcastConsentFormOpen: (event: ConsentViewerPayload) => void;
  broadcastConsentFormChange: (event: ConsentViewerPayload) => void;
  broadcastConsentClose: (event: ConsentViewerClosePayload) => void;
  broadcastRejectConsent: (event: RejectConsentPayload) => void;
  broadcastRevokeConsent: (event: RevokeConsentPayload) => void;
  onConsentFormValueChanged: (
    callback: DataCallback<ConsentViewerPayload>
  ) => Callback;
  onConsentFormAttendeeSubmitted: (
    callback: DataCallback<ConsentViewerPayload>
  ) => Callback;
  onPointerShown: (callback: DataCallback<Pointer>) => Callback;
  broadcastOrganizerMediaOpen: (media: MediaData) => void;
  broadcastOrganizerMediaClose: (media: MediaData) => void;
  broadcastOrganizerPresentationStateChange: (
    event: OrganizerPresentationStateChangePayload
  ) => void;
  broadcastWaitingRoomToInteraction: () => void;
  broadcastDisconnectClient: () => void;
  broadcastOrganizerRoomClose: () => void;
  onOrganizerTerminateSession: (callback: Callback) => Callback;
  broadcastPointerShow: (event: Pointer) => void;
  onMediaOpened: (callback: DataCallback<Media>) => Callback;
  onMediaClosed: (callback: Callback) => Callback;
  onMediaStateChanged: (
    callback: DataCallback<OrganizerPresentationStateChangePayload>
  ) => Callback;
  onSampleRequestOpened: (
    callback: DataCallback<SampleRequestViewerPayload>
  ) => Callback;
  onSampleRequestClosed: (callback: Callback) => Callback;
  onConsentFormOpened: (
    callback: DataCallback<ConsentViewerPayload>
  ) => Callback;
  onConsentClosed: (callback: Callback) => Callback;
  onRevokeConsentAttendeeConfirmed: (
    callback: DataCallback<RevokeConsentPayload>
  ) => Callback;
  onRejectConsentAttendeeConfirmed: (
    callback: DataCallback<RejectConsentPayload>
  ) => Callback;
  startScreenShare: (
    event: ScreenShareStartPayload,
    callback: (params: { token: string }) => void
  ) => void;
  stopScreenShare: () => void;
  onScreenShareStarted: (callback: Callback) => Callback;
  closeStateServerConnection: () => void;
}

type StateServerSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

const StateServerContext = createContext<StateServerProviderInterface>({
  isStateServerInitialized: false,
  totalPeopleInRoom: 0,
  setRoomInfo: DEFAULT_FN,
  initChannel: DEFAULT_FN,
  doHeartbeat: DEFAULT_FN,
  onAttendeeReadyOrganizerNotify: DEFAULT_CALLBACK,
  broadcastSampleRequestOpen: DEFAULT_FN,
  broadcastSampleRequestChange: DEFAULT_FN,
  broadcastSampleRequestClose: DEFAULT_FN,
  onSampleRequestValueChanged: DEFAULT_CALLBACK,
  onSampleRequestAttendeeSubmitted: DEFAULT_CALLBACK,
  broadcastConsentFormOpen: DEFAULT_FN,
  broadcastConsentFormChange: DEFAULT_FN,
  broadcastConsentClose: DEFAULT_FN,
  broadcastRejectConsent: DEFAULT_FN,
  broadcastRevokeConsent: DEFAULT_FN,
  onConsentFormValueChanged: DEFAULT_CALLBACK,
  onConsentFormAttendeeSubmitted: DEFAULT_CALLBACK,
  onMediaOpened: DEFAULT_CALLBACK,
  onMediaClosed: DEFAULT_CALLBACK,
  onMediaStateChanged: DEFAULT_CALLBACK,
  onSampleRequestOpened: DEFAULT_CALLBACK,
  onSampleRequestClosed: DEFAULT_CALLBACK,
  onConsentFormOpened: DEFAULT_CALLBACK,
  onConsentClosed: DEFAULT_CALLBACK,
  onPointerShown: DEFAULT_CALLBACK,
  broadcastOrganizerMediaOpen: DEFAULT_FN,
  broadcastOrganizerMediaClose: DEFAULT_FN,
  broadcastOrganizerPresentationStateChange: DEFAULT_FN,
  broadcastWaitingRoomToInteraction: DEFAULT_FN,
  broadcastDisconnectClient: DEFAULT_FN,
  broadcastOrganizerRoomClose: DEFAULT_FN,
  onOrganizerTerminateSession: DEFAULT_CALLBACK,
  broadcastPointerShow: DEFAULT_FN,
  startScreenShare: DEFAULT_FN,
  stopScreenShare: DEFAULT_FN,
  onScreenShareStarted: DEFAULT_CALLBACK,
  closeStateServerConnection: DEFAULT_FN,
  onRejectConsentAttendeeConfirmed: DEFAULT_CALLBACK,
  onRevokeConsentAttendeeConfirmed: DEFAULT_CALLBACK,
});

export const useStateServer = (): StateServerProviderInterface => {
  return useContext(StateServerContext);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const StateServerContextProvider = (props) => {
  const [totalPeopleInRoom, setTotalPeopleInRoom] = useState(0);
  const socket = useRef<StateServerSocket | null>(null);
  const [roomInfo, setRoomInfo] = useState<Room | undefined>(undefined);
  const [isStateServerInitialized, setIsStateServerInitialized] =
    useState(false);

  const { currentUser } = useCurrentUser();

  const { toast } = useNotification();
  const { t } = useTranslation();

  // cleanup listeners & disconnect
  const disconnectSocket = useCallback(() => {
    socket.current?.removeAllListeners();
    socket.current?.disconnect();
  }, [socket]);

  const updateRoom = useCallback((roomInfo: Room) => {
    setRoomInfo(roomInfo);

    const roomParticipants = roomInfo?.participants ?? {};

    const numberOfParticipants = Object.keys(roomParticipants).length;
    setTotalPeopleInRoom(numberOfParticipants);
  }, []);

  const initChannel = useCallback(
    ({
      roomId,
      role,
      hasVideoConnection,
      onTokenCreated,
      onMediaEventReplay,
      onConsentEventReplay,
      onScreenSharingEventReplay,
      onSampleRequestEventReplay,
      interactionStep,
    }: InitChannelArgs): void => {
      if (isStateServerInitialized) {
        return;
      }

      console.log('StateServer not initialized, initializing', interactionStep);

      if (!currentUser || !currentUser.person || !currentUser.person.oid) {
        console.error('currentUser / person is not defined');

        return;
      }

      const roomJoinCallback = ({ room, token }: RoomJoinCallbackParams) => {
        updateRoom(room);

        if (token && hasVideoConnection) {
          onTokenCreated?.(token, room);
        }

        // replay events for the case where the organizer refreshed the page
        handleRoomReplayEvents(room.events, {
          onMediaEventReplay,
          onConsentEventReplay,
          onScreenSharingEventReplay,
          onSampleRequestEventReplay,
        });
      };

      const currentPerson = currentUser.person;

      const soc: StateServerSocket = io(getStateServerUrl(), {
        transports: ['websocket'],
        upgrade: false,
      });

      soc.on('room_created', (payload: RoomPayload) => {
        const { room } = payload;

        updateRoom(room);
      });

      soc.on('participant_state_changed', (payload: RoomParticipantPayload) => {
        const { room, participant: changedParticipant, stateChange } = payload;

        updateRoom(room);

        if (!changedParticipant) {
          return;
        }

        // we only care about other participants
        if (changedParticipant?.id !== currentPerson.oid) {
          const displayName = changedParticipant.displayName;

          if (stateChange === 'connected') {
            if (changedParticipant.step === 'interaction') {
              toast?.({
                message: t(
                  'pages.interaction.toasts.participantJoinedInteraction',
                  {
                    displayName,
                  }
                ),
                type: 'success',
              });
            } else if (changedParticipant.step === 'waiting-room') {
              toast?.({
                message: t(
                  'pages.interaction.toasts.participantJoinedWaitingRoom',
                  {
                    displayName,
                  }
                ),
                type: 'success',
              });
            }
          } else if (stateChange === 'waiting-room-to-interaction') {
            toast?.({
              message: t(
                'pages.interaction.toasts.participantJoinedInteraction',
                {
                  displayName,
                }
              ),
              type: 'success',
            });
          } else if (stateChange === 'disconnected') {
            toast?.({
              message: t('pages.interaction.toasts.participantLeft', {
                displayName,
              }),
              type: 'warning',
            });
          }
        }
      });

      soc.on('connect_error', () => {
        console.log('showErrorNotification(Cannot connect to state-server)');
      });

      soc.on('connect', () => {
        const displayName = getPersonFullNameForInteraction(currentPerson);

        if (currentPerson && currentPerson.oid) {
          soc.emit(
            'room_join',
            {
              roomId: unpackNodeId(roomId),
              realm: getRealm(),
              participant: {
                id: currentPerson.oid,
                displayName,
                role,
                step: interactionStep,
              },
              restricted: false,
              hasVideoConnection,
            },
            roomJoinCallback
          );
        } else {
          console.error('currentUser / person is not defined');
        }
      });

      setIsStateServerInitialized(true);
      socket.current = soc;
      console.log('StateServer initialized');
    },
    [currentUser, isStateServerInitialized, t, toast, updateRoom]
  );

  const doHeartbeat = useCallback(
    (
      participant: Participant,
      callback: DataCallback<HeartbeatCallbackParams>
    ) => {
      socket.current?.emit('heartbeat', { participant }, callback);
    },
    []
  );

  const subscribeToEvent = useCallback(
    ({ name, callback }: EventSubscription): Callback => {
      socket.current?.on(name, callback);

      return (): void => {
        socket.current?.removeListener(name, callback);
      };
    },
    []
  );

  const subscribeToMultipleEvents = useCallback(
    ({ names, callback }: MultiEventSubscription): Callback => {
      const callbacks: Array<Callback> = [];
      names.forEach((name: OnEventNames) => {
        const innerCallback = subscribeToEvent({ name, callback });
        callbacks.push(innerCallback);
      });

      return (): void => {
        callbacks.forEach((cb) => cb());
      };
    },
    [subscribeToEvent]
  );

  const emit = useCallback(
    (eventName: EmitEventNames, event?: EmitEventPayloads): void => {
      socket.current?.emit(eventName, event);
    },
    []
  );

  // <----------- EVENT SUBSCRIPTIONS Organizer & CoOrganizer----------->

  const onAttendeeReadyOrganizerNotify = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'attendee_ready_organizer_notified',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onSampleRequestValueChanged = useCallback(
    (callback: DataCallback<SampleRequestViewerPayload>): Callback => {
      return subscribeToEvent({
        name: 'sample_request_viewer_state_changed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onSampleRequestAttendeeSubmitted = useCallback(
    (callback: DataCallback<SampleRequestViewerPayload>): Callback => {
      return subscribeToEvent({
        name: 'attendee_sample_request_submitted',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onConsentFormValueChanged = useCallback(
    (callback: DataCallback<ConsentViewerPayload>): Callback => {
      return subscribeToEvent({
        name: 'consent_viewer_state_changed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onConsentFormAttendeeSubmitted = useCallback(
    (callback: DataCallback<ConsentViewerPayload>): Callback => {
      return subscribeToEvent({
        name: 'attendee_consent_submitted',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onRejectConsentAttendeeConfirmed = useCallback(
    (callback: DataCallback<RejectConsentPayload>): Callback => {
      return subscribeToEvent({
        name: 'attendee_consent_reject_confirmed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onRevokeConsentAttendeeConfirmed = useCallback(
    (callback: DataCallback<RevokeConsentPayload>): Callback => {
      return subscribeToEvent({
        name: 'attendee_consent_revoke_confirmed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onPointerShown = useCallback(
    (callback: DataCallback<Pointer>): Callback => {
      socket.current?.on('pointer_shown', callback);

      return (): void => {
        socket.current?.removeListener('pointer_shown', callback);
      };
    },
    []
  );

  // <----------- EVENT SUBSCRIPTIONS CoOrganizer only ----------->

  // All of these three events are handled in the same way:
  // The coOrganizer disconnects from the room
  const onOrganizerTerminateSession = useCallback(
    (callback: Callback): Callback => {
      return subscribeToMultipleEvents({
        names: [
          'organizer_do_client_disconnected',
          'organizer_room_closed',
          'session_expired',
        ],
        callback,
      });
    },
    [subscribeToMultipleEvents]
  );

  const onMediaOpened = useCallback(
    (callback: DataCallback<Media>): Callback => {
      socket.current?.on('organizer_media_opened', callback);

      return (): void => {
        socket.current?.removeListener('organizer_media_opened', callback);
      };
    },
    []
  );

  const onMediaClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_media_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onMediaStateChanged = useCallback(
    (
      callback: DataCallback<OrganizerPresentationStateChangePayload>
    ): Callback => {
      socket.current?.on('organizer_presentation_state_changed', callback);

      return (): void => {
        socket.current?.removeListener(
          'organizer_presentation_state_changed',
          callback
        );
      };
    },
    []
  );

  const onSampleRequestOpened = useCallback(
    (callback: DataCallback<SampleRequestViewerPayload>): Callback => {
      socket.current?.on('organizer_sample_request_opened', callback);

      return (): void => {
        socket.current?.removeListener(
          'organizer_sample_request_opened',
          callback
        );
      };
    },
    []
  );

  const onSampleRequestClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'sample_request_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onConsentFormOpened = useCallback(
    (callback: DataCallback<ConsentViewerPayload>): Callback => {
      socket.current?.on('organizer_consent_opened', callback);

      return (): void => {
        socket.current?.removeListener('organizer_consent_opened', callback);
      };
    },
    []
  );

  const onConsentClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'consent_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  // < ----------- EVENT BROADCASTS ----------->

  const broadcastOrganizerMediaOpen = useCallback(
    (media: MediaData) => {
      const event = convertMediaDataToMediaEvent(media);

      emit('organizer_media_open', event);
    },
    [emit]
  );

  const broadcastOrganizerMediaClose = useCallback(
    (media: MediaData) => {
      const event = convertMediaDataToMediaEvent(media);

      emit('organizer_media_close', event);
    },
    [emit]
  );

  const broadcastSampleRequestOpen = useCallback(
    (event: SampleRequestViewerPayload) => {
      emit('organizer_sample_request_open', event);
    },
    [emit]
  );

  const broadcastSampleRequestChange = useCallback(
    (event: SampleRequestViewerPayload) => {
      emit('sample_request_viewer_state_change', event);
    },
    [emit]
  );

  const broadcastSampleRequestClose = useCallback(
    (event: SampleRequestViewerClosePayload) => {
      emit('sample_request_close', event);
    },
    [emit]
  );

  const broadcastConsentFormOpen = useCallback(
    (event: ConsentViewerPayload) => {
      emit('organizer_consent_open', event);
    },
    [emit]
  );

  const broadcastConsentFormChange = useCallback(
    (event: ConsentViewerPayload) => {
      emit('consent_viewer_state_change', event);
    },
    [emit]
  );

  const broadcastConsentClose = useCallback(
    (event: ConsentViewerClosePayload) => {
      emit('consent_close', event);
    },
    [emit]
  );

  const broadcastRejectConsent = useCallback(
    (event: RejectConsentPayload) => {
      emit('organizer_consent_reject', event);
    },
    [emit]
  );

  const broadcastRevokeConsent = useCallback(
    (event: RevokeConsentPayload) => {
      emit('organizer_consent_revoke', event);
    },
    [emit]
  );

  const broadcastOrganizerPresentationStateChange = useCallback(
    (event: OrganizerPresentationStateChangePayload) => {
      emit('organizer_presentation_state_change', event);
    },
    [emit]
  );

  const broadcastWaitingRoomToInteraction = useCallback((): void => {
    emit('waiting_room_to_interaction_join');
  }, [emit]);

  const broadcastDisconnectClient = useCallback(() => {
    emit('organizer_do_client_disconnect');
  }, [emit]);

  const broadcastOrganizerRoomClose = useCallback(() => {
    emit('organizer_close_room');
  }, [emit]);

  const broadcastPointerShow = useCallback(
    (event: Pointer) => {
      emit('pointer_show', event);
    },
    [emit]
  );

  // <----------- Other Emits ----------->
  const startScreenShare = useCallback(
    (
      event: ScreenShareStartPayload,
      callback: (params: { token: string }) => void
    ) => {
      socket.current?.emit('screen_share_start', event, callback);
    },
    []
  );

  const stopScreenShare = useCallback(() => {
    emit('screen_share_stop');
  }, [emit]);

  const onScreenShareStarted = useCallback(
    (callback: Callback) => {
      return subscribeToEvent({ name: 'screen_share_started', callback });
    },
    [subscribeToEvent]
  );

  const closeStateServerConnection = useCallback(() => {
    disconnectSocket();

    setIsStateServerInitialized(false);
    setRoomInfo(undefined);
  }, [disconnectSocket]);

  const contextValue = useMemo(
    () => ({
      isStateServerInitialized,
      roomInfo,
      setRoomInfo,
      initChannel,
      doHeartbeat,
      onAttendeeReadyOrganizerNotify,
      onPointerShown,
      broadcastOrganizerMediaOpen,
      broadcastOrganizerMediaClose,
      onMediaOpened,
      onMediaClosed,
      onMediaStateChanged,

      broadcastSampleRequestOpen,
      broadcastSampleRequestChange,
      broadcastSampleRequestClose,
      onSampleRequestValueChanged,
      onSampleRequestAttendeeSubmitted,
      onSampleRequestOpened,
      onSampleRequestClosed,

      broadcastConsentFormOpen,
      broadcastConsentFormChange,
      broadcastConsentClose,
      broadcastRejectConsent,
      broadcastRevokeConsent,
      onConsentFormValueChanged,
      onConsentFormAttendeeSubmitted,
      onConsentFormOpened,
      onConsentClosed,
      onRejectConsentAttendeeConfirmed,
      onRevokeConsentAttendeeConfirmed,

      broadcastOrganizerPresentationStateChange,
      broadcastDisconnectClient,
      broadcastOrganizerRoomClose,
      onOrganizerTerminateSession,
      broadcastWaitingRoomToInteraction,
      broadcastPointerShow,
      startScreenShare,
      stopScreenShare,
      onScreenShareStarted,
      closeStateServerConnection,
      totalPeopleInRoom,
    }),
    [
      isStateServerInitialized,
      roomInfo,
      initChannel,
      doHeartbeat,
      onAttendeeReadyOrganizerNotify,
      onPointerShown,
      broadcastOrganizerMediaOpen,
      broadcastOrganizerMediaClose,
      onMediaOpened,
      onMediaClosed,
      onMediaStateChanged,
      broadcastSampleRequestOpen,
      broadcastSampleRequestChange,
      broadcastSampleRequestClose,
      onSampleRequestValueChanged,
      onSampleRequestAttendeeSubmitted,
      onSampleRequestOpened,
      onSampleRequestClosed,
      broadcastConsentFormOpen,
      broadcastConsentFormChange,
      broadcastConsentClose,
      broadcastRejectConsent,
      broadcastRevokeConsent,
      onConsentFormValueChanged,
      onConsentFormAttendeeSubmitted,
      onConsentFormOpened,
      onConsentClosed,
      onRejectConsentAttendeeConfirmed,
      onRevokeConsentAttendeeConfirmed,
      broadcastOrganizerPresentationStateChange,
      broadcastDisconnectClient,
      broadcastOrganizerRoomClose,
      onOrganizerTerminateSession,
      broadcastWaitingRoomToInteraction,
      broadcastPointerShow,
      startScreenShare,
      stopScreenShare,
      onScreenShareStarted,
      closeStateServerConnection,
      totalPeopleInRoom,
    ]
  );

  return <StateServerContext.Provider {...props} value={contextValue} />;
};
