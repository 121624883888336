import { useLocalStorage } from '@ysura/common';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { OrderBy } from '@/services/graphql/types';

type UseMediaContextInitialValue = {
  mediaLayout: string;
  changeMediaLayout?: FunctionStringCallback;
  mediaOrder: OrderBy;
  changeMediaOrder?: FunctionStringCallback;
  mediaQueryString: string;
  changeMediaQueryString?: FunctionStringCallback;
};

export const useMediaContextInitialValue: UseMediaContextInitialValue = {
  mediaLayout: 'grid',
  mediaOrder: OrderBy.ASC,
  mediaQueryString: '',
};

const MediaContext = createContext(useMediaContextInitialValue);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const MediaContextProvider = (props) => {
  const [mediaLayout, setMediaLayout] = useLocalStorage<'grid' | 'list'>(
    'mediaLayout',
    'grid'
  );
  const [mediaOrder, setMediaOrder] = useLocalStorage<OrderBy>(
    'mediaOrder',
    OrderBy.ASC
  );
  const [mediaQueryString, setMediaQueryString] = useState('');

  const changeMediaLayout = useCallback(
    (
      newLayout: 'grid' | 'list' | ((val: 'grid' | 'list') => 'grid' | 'list')
    ) => {
      setMediaLayout(newLayout);
    },
    [setMediaLayout]
  );
  const changeMediaOrder = useCallback(
    (newOrder: OrderBy | ((val: OrderBy) => OrderBy)) => {
      setMediaOrder(newOrder);
    },
    [setMediaOrder]
  );
  const changeMediaQueryString = useCallback(
    (newQueryString: ((prevState: string) => string) | string) => {
      setMediaQueryString(newQueryString);
    },
    [setMediaQueryString]
  );

  const values = useMemo(
    () => ({
      mediaLayout,
      changeMediaLayout,
      mediaOrder,
      changeMediaOrder,
      mediaQueryString,
      changeMediaQueryString,
    }),
    [
      mediaLayout,
      changeMediaLayout,
      mediaOrder,
      changeMediaOrder,
      mediaQueryString,
      changeMediaQueryString,
    ]
  );

  return <MediaContext.Provider value={values} {...props} />;
};

export const useMediaContext = () => {
  return useContext(MediaContext);
};
