import * as Sentry from '@sentry/react';
import {
  getVideoElementStatus,
  ReactProps,
  Room,
  Token,
  useLocalStorage,
} from '@ysura/common';
import { ExceptionEvent, OpenVidu, Publisher, Stream } from 'openvidu-browser';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SYSTEM } from '@/config';
import { useCurrentUser, useNotification } from '@/hooks';

import {
  DEFAULT_FN,
  ParticipantDeviceState,
  UseVideoState,
  VideoStatus,
} from './useInteractionTypes';
import { useStateServer } from './useStateServer';

export const useVideoStateInitialState: UseVideoState = {
  audioDevices: [],
  videoDevices: [],

  selectedVideoInput: '',
  selectedAudioInput: '',

  isUserMediaCollected: false,
  isMicAccessDisabled: true,
  isCameraAccessDisabled: true,
  isMicActive: true,
  isCameraActive: true,

  isOwnScreenShared: false,
  isParticipantsScreenShared: false,

  participantDeviceState: {},
  participantVideoStatus: {},

  initializeVideo: DEFAULT_FN,
  handleVideoTokenGenerated: DEFAULT_FN,
  leaveVideoCall: DEFAULT_FN,

  startScreenSharing: DEFAULT_FN,
  handleNotifyScreenShareStarted: DEFAULT_FN,
  stopScreenSharing: DEFAULT_FN,

  setAudioDevices: DEFAULT_FN,
  setVideoDevices: DEFAULT_FN,
  setSelectedVideoInput: DEFAULT_FN,
  setSelectedAudioInput: DEFAULT_FN,
  setIsUserMediaCollected: DEFAULT_FN,
  setIsMicAccessDisabled: DEFAULT_FN,
  setIsCameraAccessDisabled: DEFAULT_FN,
  toggleIsMicActive: DEFAULT_FN,
  toggleIsCameraActive: DEFAULT_FN,
};

const VideoContext = createContext(useVideoStateInitialState);

export const VideoStateContextProvider: FC<ReactProps> = (props) => {
  // OpenVidu
  const openVidu = useRef<OpenVidu | null>(null);
  const publisher = useRef<Publisher | null>(null);
  const [isVideoSessionInitialized, setIsVideoSessionInitialized] =
    useState<boolean>(false);
  const [isVideoSessionConnected, setIsVideoSessionConnected] =
    useState<boolean>(false);
  const [videoToken, setVideoToken] = useState<string>('');

  const [audioDevices, setAudioDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [videoDevices, setVideoDevices] = useState<Array<MediaDeviceInfo>>([]);

  const [participantDeviceState, setParticipantDeviceState] = useState<
    Record<string, ParticipantDeviceState>
  >({});

  const [participantVideoStatus, setParticipantVideoStatus] = useState<
    Record<string, VideoStatus>
  >({});

  // ScreenShare
  const ovScreenShare = useRef<OpenVidu | null>(null);
  const screenSharePublisher = useRef<Publisher | null>(null);
  const [isUserMediaCollected, setIsUserMediaCollected] = useState(false);

  const [selectedVideoInput, setSelectedVideoInputInternal] = useLocalStorage(
    'selectedVideoInput',
    ''
  );
  const [selectedAudioInput, setSelectedAudioInputInternal] = useLocalStorage(
    'selectedAudioInput',
    ''
  );
  const [isMicActive, setIsMicActiveInternal] = useLocalStorage(
    'isMicActive',
    true
  );
  const [isCameraActive, setIsCameraActiveInternal] = useLocalStorage(
    'isCameraActive',
    true
  );

  const [isMicAccessDisabled, setIsMicAccessDisabled] = useState(true);
  const [isCameraAccessDisabled, setIsCameraAccessDisabled] = useState(true);

  const [isOwnScreenShared, setIsOwnScreenShared] = useState(false);
  const [isParticipantsScreenShared, setIsParticipantsScreenShared] =
    useState(false);

  const {
    startScreenShare: getScreenShareToken,
    roomInfo: room,
    stopScreenShare: stopScreenShareInternal,
  } = useStateServer();

  const { toast } = useNotification();
  const { t } = useTranslation();

  const { currentUser } = useCurrentUser();

  /*****************************
   * Helpers                   *
   *****************************/

  const getParticipantIdFromStream = (stream: Stream) => {
    const connectionData = JSON.parse(stream.connection.data);

    return connectionData?.participantId;
  };

  const isOwnStream = useCallback(
    (stream: Stream) => {
      const streamParticipantId = getParticipantIdFromStream(stream);

      const currentUserId = currentUser?.person?.oid;

      if (!currentUserId) {
        return false;
      }

      return (
        streamParticipantId === currentUserId ||
        streamParticipantId === `screenshare-${currentUserId}`
      );
    },
    [currentUser?.person?.oid]
  );

  const setDeviceStateForParticipant = useCallback(
    (participantId: string, state: ParticipantDeviceState) => {
      setParticipantDeviceState((prevState) => ({
        ...prevState,
        [participantId]: state,
      }));
    },
    [setParticipantDeviceState]
  );

  const setParticipantVideoShouldBePlaying = useCallback(
    (participantId: string) => {
      setParticipantVideoStatus((prevState) => ({
        ...prevState,
        [participantId]: {
          isPlaying: false,
          shouldBePlaying: true,
        },
      }));
    },
    [setParticipantVideoStatus]
  );

  const setParticipantVideoPlaying = useCallback(
    (participantId: string) => {
      setParticipantVideoStatus((prevState) => {
        const currentStatus = prevState[participantId];

        if (!currentStatus) {
          return prevState;
        }

        return {
          ...prevState,
          [participantId]: {
            ...currentStatus,
            isPlaying: true,
          },
        };
      });
    },
    [setParticipantVideoStatus]
  );

  const getVideoContainerId = (participantId: string) =>
    `video-container-${participantId}`;

  /*****************************
   * Video / OpenVidu          *
   *****************************/
  const stopMediaTracks = useCallback((publisher: Publisher) => {
    if (publisher) {
      publisher.stream
        ?.getMediaStream()
        ?.getTracks()
        .forEach((track) => {
          track.stop();
        });
    }
  }, []);

  const cleanUpOvScreenShare = useCallback(async () => {
    if (screenSharePublisher.current) {
      stopMediaTracks(screenSharePublisher.current);
      await ovScreenShare.current?.session?.unpublish(
        screenSharePublisher.current
      );
      screenSharePublisher.current = null;
    }

    ovScreenShare.current?.session?.disconnect();
    ovScreenShare.current = null;
  }, [stopMediaTracks]);

  const cleanUpOV = useCallback(async () => {
    await cleanUpOvScreenShare();

    if (publisher.current) {
      stopMediaTracks(publisher.current);
      await openVidu.current?.session?.unpublish(publisher.current);
      publisher.current = null;
    }

    openVidu.current?.session?.disconnect();
    openVidu.current = null;

    setIsVideoSessionConnected(false);
    setIsVideoSessionInitialized(false);
    setVideoToken('');
  }, [cleanUpOvScreenShare, stopMediaTracks]);

  useEffect(() => {
    window.addEventListener('beforeunload', cleanUpOV);

    return () => {
      window.removeEventListener('beforeunload', cleanUpOV);
    };
  }, [cleanUpOV]);

  const handleVideoTokenGenerated = useCallback(
    (token: string, room: Room) => {
      console.log('handleTokenGenerated: ', token);

      let ov = openVidu.current;

      if (!ov) {
        ov = new OpenVidu();
        SYSTEM.IS_PROD && ov.enableProdMode();
        openVidu.current = ov;
      }

      if (!ov.session || !isVideoSessionInitialized) {
        const session = ov.initSession();

        console.log('Initializing OpenVidu Session');

        session.on('streamCreated', (event) => {
          if (isOwnStream(event.stream)) {
            return;
          }

          const isVideoStream =
            (event.stream.hasVideo && event.stream.typeOfVideo === 'CAMERA') ||
            (!event.stream.hasVideo && event.stream.hasAudio);

          const isScreenShareStream =
            event.stream.hasVideo && event.stream.typeOfVideo === 'SCREEN';

          // Subscriber started streaming video
          if (isVideoStream) {
            const streamParticipantId = getParticipantIdFromStream(
              event.stream
            );

            console.log(
              'Video stream connected',
              streamParticipantId,
              room?.participants?.[streamParticipantId]?.role
            );

            // Subscribe to the Stream to receive it. HTML video will be appended
            const subscriber = session.subscribe(
              event.stream,
              getVideoContainerId(streamParticipantId)
            );

            // When the HTML video has been appended to DOM...
            subscriber.on('videoElementCreated', async (createdEvent) => {
              const videoElement = createdEvent.element;
              setParticipantVideoShouldBePlaying(streamParticipantId);

              videoElement.onplaying = () => {
                console.log(
                  'Video playing for subscriber',
                  streamParticipantId
                );

                setParticipantVideoPlaying(streamParticipantId);
              };

              console.log(
                'Video element created. Status:',
                getVideoElementStatus(videoElement)
              );

              try {
                // First, set playsinline ...
                videoElement.playsInline = true;
                // ... then, play the video again to increase chance of autoplay.
                await videoElement.play();

                console.log(
                  'Video started playing for susbscriber:',
                  getVideoElementStatus(videoElement)
                );
              } catch (e) {
                Sentry.captureException(e, {
                  user: {
                    id: currentUser?.person?.oid,
                    username: currentUser?.username,
                  },
                  extra: {
                    participantId: streamParticipantId,
                    room,
                    videoElementStatus: getVideoElementStatus(videoElement),
                  },
                });

                console.error(
                  'Could not play subscriber video:',
                  e,
                  'Status: ',
                  getVideoElementStatus(videoElement)
                );
              }
            });

            // On every Stream destroyed...
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            session.on('streamDestroyed', (desctroyedEvent) => {
              // no-op
            });

            session.on('streamPropertyChanged', (propertyEvent) => {
              if (!isOwnStream(propertyEvent.stream)) {
                const streamPropertyChangedParticipantId =
                  getParticipantIdFromStream(propertyEvent.stream);

                setDeviceStateForParticipant(
                  streamPropertyChangedParticipantId,
                  {
                    isCameraActive: propertyEvent.stream.videoActive,
                    isMicActive: propertyEvent.stream.audioActive,
                  }
                );
              }
            });

            setDeviceStateForParticipant(streamParticipantId, {
              isCameraActive: event.stream.videoActive,
              isMicActive: event.stream.audioActive,
            });
          }

          // Subscriber started streaming screen share
          else if (isScreenShareStream) {
            console.log('Screen sharing started');

            const screenShareSubscriber = session.subscribe(
              event.stream,
              // ID of the DOM element (see ScreenShareColumn.tsx)
              'participantScreenShare'
            );

            // screen share video session IDs always have the format 'screenshare-<participantId>'
            const screenShareParticipantId = getParticipantIdFromStream(
              screenShareSubscriber.stream
            ).replace('screenshare-', '');
            const displayName =
              room?.participants?.[screenShareParticipantId]?.displayName ??
              'Participant';

            screenShareSubscriber.on(
              'videoElementCreated',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              (createdEvent) => {
                setIsParticipantsScreenShared(true);

                toast?.({
                  message: t(
                    'pages.interaction.toasts.participantStartedScreenSharing',
                    { displayName }
                  ),
                  type: 'info',
                });
              }
            );

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            session.on('streamDestroyed', (destroyedEvent) => {
              setIsParticipantsScreenShared?.(false);

              toast?.({
                message: t(
                  'pages.interaction.toasts.participantStoppedScreenSharing',
                  { displayName }
                ),
                type: 'info',
              });
            });
          }

          // Subscriber started streaming something we cannot handle
          else {
            console.log('Unknown video type');
          }
        });

        // On every asynchronous exception...
        session.on('exception', (exception) => {
          console.warn(exception);
        });

        setIsVideoSessionInitialized(true);
      }

      if (token) {
        setVideoToken(token);
      } else {
        console.error('No video token found in callback');
      }
    },
    [
      currentUser?.person?.oid,
      currentUser?.username,
      isOwnStream,
      isVideoSessionInitialized,
      setDeviceStateForParticipant,
      setParticipantVideoPlaying,
      setParticipantVideoShouldBePlaying,
      t,
      toast,
    ]
  );

  const initializeVideo = useCallback(() => {
    if (!openVidu.current) {
      console.log('initializeVideo: OpenVidu not initialized, returning');

      return;
    }

    if (!openVidu.current?.session) {
      console.log(
        'initializeVideo: OpenVidu Session not initialized, returning'
      );

      return;
    }

    if (publisher.current) {
      console.log('initializeVideo: Publisher already published, returning');

      return;
    }

    if (!isUserMediaCollected) {
      console.log('initializeVideo: isUserMediaCollected not set, returning');

      return;
    }

    if (!isVideoSessionConnected) {
      console.log('Connecting to OpenVidu Session');

      const currentUserId = currentUser?.person?.oid;

      if (videoToken && currentUserId) {
        openVidu.current.session
          .connect(videoToken)
          .then(() => {
            setIsVideoSessionConnected(true);

            const newPublisher = openVidu.current?.initPublisher(
              getVideoContainerId(currentUserId),
              {
                audioSource: selectedAudioInput,
                videoSource: selectedVideoInput,
                publishAudio: !isMicAccessDisabled && isMicActive,
                publishVideo: !isCameraAccessDisabled && isCameraActive,
              }
            );

            if (newPublisher) {
              newPublisher.on('videoElementCreated', async (createdEvent) => {
                const videoElement = createdEvent.element;
                videoElement.playsInline = true;
                setParticipantVideoShouldBePlaying(currentUserId);

                videoElement.onplaying = () => {
                  console.log('Video playing for publisher');

                  setParticipantVideoPlaying(currentUserId);
                };
              });

              openVidu.current?.session.publish(newPublisher);
              publisher.current = newPublisher;
            }
          })
          .catch((error) => {
            console.error('Error connecting to video session: ', error);
          });
      }
    }
  }, [
    isUserMediaCollected,
    isVideoSessionConnected,
    currentUser?.person?.oid,
    videoToken,
    selectedAudioInput,
    selectedVideoInput,
    isMicAccessDisabled,
    isMicActive,
    isCameraAccessDisabled,
    isCameraActive,
    setParticipantVideoShouldBePlaying,
    setParticipantVideoPlaying,
  ]);

  /*****************************
   * General                   *
   *****************************/

  const leaveVideoCall = useCallback(() => {
    cleanUpOV();

    setIsVideoSessionConnected(false);
    setIsVideoSessionInitialized(false);

    setIsOwnScreenShared(false);
    setIsParticipantsScreenShared(false);
  }, [cleanUpOV]);

  /*****************************
   * Audio and Video           *
   *****************************/

  const toggleIsCameraActive = useCallback(() => {
    setIsCameraActiveInternal((current) => {
      const newValue = !current;
      publisher.current?.publishVideo(newValue);

      return newValue;
    });
  }, [setIsCameraActiveInternal]);

  const toggleIsMicActive = useCallback(() => {
    setIsMicActiveInternal((current) => {
      const newValue = !current;
      publisher.current?.publishAudio(newValue);

      return newValue;
    });
  }, [setIsMicActiveInternal]);

  const setSelectedVideoInput = useCallback(
    async (value: string) => {
      setSelectedVideoInputInternal(value);

      // only change the video input if we have a publisher
      // (i.e. we are connected to the session and not in the waiting room)
      if (openVidu.current && publisher.current) {
        const mediaStream = await openVidu.current.getUserMedia({
          audioSource: false,
          videoSource: value,
        });

        // Getting the video track from mediaStream
        const videoTrack = mediaStream.getVideoTracks()[0];

        // Replacing the video track
        publisher.current
          .replaceTrack(videoTrack)
          .then(() => {
            console.log('New track has been published');
          })
          .catch((error) => console.error('Error replacing track', error));
      }
    },
    [setSelectedVideoInputInternal]
  );

  const setSelectedAudioInput = useCallback(
    async (value: string) => {
      setSelectedAudioInputInternal(value);

      // only change the audio input if we have a publisher
      // (i.e. we are connected to the session and not in the waiting room)
      if (openVidu.current && publisher.current) {
        const mediaStream = await openVidu.current.getUserMedia({
          audioSource: value,
          videoSource: false,
        });

        // Getting the audio track from mediaStream
        const audioTrack = mediaStream.getAudioTracks()[0];

        // Replacing the audio track
        publisher.current
          .replaceTrack(audioTrack)
          .then(() => {
            console.log('New track has been published');
          })
          .catch((error) => console.error('Error replacing track', error));
      }
    },
    [setSelectedAudioInputInternal]
  );

  /*************************
   *  Screen Sharing       *
   * **********************/

  const onScreenShareTokenCreated = useCallback(
    ({ token }: Token) => {
      console.log('onScreenShareTokenCreated: ', token);

      const screenShareSession = ovScreenShare.current?.initSession();

      if (!screenShareSession) {
        console.error(
          'Could not initialize screen share session in token callback'
        );

        return;
      }

      console.log(
        'Initializing OpenVidu Screen Share Session',
        screenShareSession
      );

      // On every asynchronous exception...
      screenShareSession.on('exception', (exception: ExceptionEvent) => {
        console.warn(exception);
      });

      screenShareSession.connect(token).then(() => {
        console.log('Screen share session connected');

        if (!screenSharePublisher.current) {
          console.log('Screen share publisher not found');
        } else {
          screenShareSession.publish(screenSharePublisher.current).then(() => {
            console.log('Screen share publisher published');

            setIsOwnScreenShared(true);

            toast?.({
              message: t('pages.interaction.toasts.startedSharing'),
              type: 'success',
            });
          });
        }
      });
    },
    [t, toast]
  );

  const startScreenSharing = useCallback(
    (shouldBroadcast?: boolean, participantSharingId?: string) => {
      if (isOwnScreenShared) {
        return;
      }

      console.log('Starting screen sharing');

      let ov = ovScreenShare.current;

      if (!ov) {
        ov = new OpenVidu();
        SYSTEM.IS_PROD && ov.enableProdMode();
        ovScreenShare.current = ov;
      }

      ov
        // 'undefined' here means that we do not display the shared screen on the (co-)organizer side
        ?.initPublisherAsync(undefined, {
          videoSource: 'screen',
          // Turn off audio for screen sharing to prevent auto-play issues
          publishAudio: false,
          audioSource: false,
        })
        .then((screenPublisher) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          screenPublisher.once('accessAllowed', (_event) => {
            screenPublisher.stream
              .getMediaStream()
              .getVideoTracks()[0]
              .addEventListener('ended', () => {
                console.log('User pressed the "Stop sharing" button');

                screenPublisher.session.unpublish(screenPublisher).then(() => {
                  console.log('Screen share publisher unpublished');

                  toast?.({
                    message: t('pages.interaction.toasts.stoppedSharing'),
                    type: 'info',
                  });

                  stopScreenShareInternal();
                  cleanUpOvScreenShare();
                  setIsOwnScreenShared(false);
                });
              });
          });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          screenPublisher.once('accessDenied', (_event) => {
            toast?.({
              type: 'warning',
              message: t('pages.interaction.toasts.screenSharingDenied'),
            });
          });

          screenSharePublisher.current = screenPublisher;
          getScreenShareToken(
            { shouldBroadcast, participantSharingId },
            ({ token }) => onScreenShareTokenCreated({ token })
          );
        })
        .catch((error) => {
          console.error('Error starting screen sharing: ', error);

          toast?.({
            type: 'error',
            message: t('pages.interaction.toasts.screenSharingError'),
          });
        });
    },
    [
      cleanUpOvScreenShare,
      getScreenShareToken,
      isOwnScreenShared,
      onScreenShareTokenCreated,
      stopScreenShareInternal,
      t,
      toast,
    ]
  );

  const handleScreenShareTokenCreated = useCallback(
    ({ token }: Token) => {
      let ov = ovScreenShare.current;

      if (!ov) {
        ov = new OpenVidu();
        SYSTEM.IS_PROD && ov.enableProdMode();
        ovScreenShare.current = ov;
      }

      if (!token) {
        console.error('No screen share token found in callback');

        return;
      }

      const screenShareReceiveSession = ov.initSession();

      console.log('Initializing OpenVidu Session for screen share receiving');

      screenShareReceiveSession.on('exception', (exception) => {
        console.warn(exception);
      });

      screenShareReceiveSession.on('streamCreated', (event) => {
        if (isOwnStream(event.stream)) {
          return;
        }
        const isScreenShareStream =
          event.stream.hasVideo && event.stream.typeOfVideo === 'SCREEN';

        if (isScreenShareStream) {
          const screenShareSubscriber = screenShareReceiveSession.subscribe(
            event.stream,
            'participantScreenShare',
            {
              subscribeToAudio: false,
            }
          );

          const screenShareParticipantId = getParticipantIdFromStream(
            screenShareSubscriber.stream
          ).replace('screenshare-', '');
          const displayName =
            room?.participants?.[screenShareParticipantId]?.displayName ??
            'Participant';

          screenShareSubscriber.on(
            'videoElementCreated',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async (createdEvent) => {
              // We mute the screen share video element and start playing it
              // to prevent the browser from blocking the video element.
              // See also https://developer.chrome.com/blog/autoplay/
              try {
                createdEvent.element.muted = true;
                await createdEvent.element.play();
              } catch (e) {
                console.error(e);
              }

              setIsParticipantsScreenShared(true);

              toast?.({
                message: t(
                  'pages.interaction.toasts.participantStartedScreenSharing',
                  { displayName }
                ),
                type: 'info',
              });
            }
          );

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          screenShareReceiveSession.on('streamDestroyed', (destroyedEvent) => {
            setIsParticipantsScreenShared?.(false);

            toast?.({
              message: t(
                'pages.interaction.toasts.participantStoppedScreenSharing',
                { displayName }
              ),
              type: 'info',
            });

            cleanUpOvScreenShare();
          });
        } else {
          console.log('Unknown video type');
        }
      });

      if (!screenShareReceiveSession.connection?.connectionId) {
        screenShareReceiveSession
          .connect(token)
          .then(() => {
            console.log('Screen share receive session connected');
          })
          .catch((error) => {
            console.error(
              'Error connecting to screen sharesession for attendee: ',
              error
            );

            // TODO show error to user
          });
      }
    },
    [cleanUpOvScreenShare, isOwnStream, room?.participants, t, toast]
  );

  const handleNotifyScreenShareStarted = useCallback(() => {
    getScreenShareToken?.(
      { shouldBroadcast: false },
      handleScreenShareTokenCreated
    );
  }, [getScreenShareToken, handleScreenShareTokenCreated]);

  const stopScreenSharing = useCallback(() => {
    if (screenSharePublisher.current) {
      ovScreenShare.current?.session
        .unpublish(screenSharePublisher.current)
        .then(() => {
          toast?.({
            message: t('pages.interaction.toasts.stoppedSharing'),
            type: 'info',
          });
          setIsOwnScreenShared(false);
          stopScreenShareInternal();
          cleanUpOvScreenShare();
        });
    }
  }, [cleanUpOvScreenShare, stopScreenShareInternal, t, toast]);

  const values = useMemo(
    () => ({
      selectedVideoInput,
      selectedAudioInput,
      isMicAccessDisabled,
      isCameraAccessDisabled,
      isMicActive,
      isCameraActive,
      audioDevices,
      videoDevices,
      isUserMediaCollected,

      isOwnScreenShared,
      isParticipantsScreenShared,
      setIsOwnScreenShared,
      setIsParticipantsScreenShared,

      participantDeviceState,
      participantVideoStatus,

      setSelectedVideoInput,
      setSelectedAudioInput,
      setIsMicAccessDisabled,
      setIsCameraAccessDisabled,
      toggleIsMicActive,
      toggleIsCameraActive,
      setAudioDevices,
      setVideoDevices,
      setIsUserMediaCollected,
      initializeVideo,
      leaveVideoCall,
      handleVideoTokenGenerated,
      startScreenSharing,
      handleNotifyScreenShareStarted,
      stopScreenSharing,
    }),
    [
      selectedVideoInput,
      selectedAudioInput,
      isMicAccessDisabled,
      isCameraAccessDisabled,
      isMicActive,
      isCameraActive,
      audioDevices,
      videoDevices,
      isUserMediaCollected,
      isOwnScreenShared,
      isParticipantsScreenShared,
      participantDeviceState,
      participantVideoStatus,
      setSelectedVideoInput,
      setSelectedAudioInput,
      toggleIsMicActive,
      toggleIsCameraActive,
      initializeVideo,
      leaveVideoCall,
      handleVideoTokenGenerated,
      startScreenSharing,
      handleNotifyScreenShareStarted,
      stopScreenSharing,
    ]
  );

  return <VideoContext.Provider value={values} {...props} />;
};

export const useVideoState = () => {
  return useContext(VideoContext);
};
