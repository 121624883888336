import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { useMediaQuery, useTheme } from '@mui/material';
import { useElementSize } from '@ysura/common';
import { useEffect, useRef, useState } from 'react';

import { useInteraction } from '@/hooks';

enum ASPECT {
  contain = 'contain',
  cover = 'cover',
}

type UsePDFControllingProps = {
  initialPage?: number;
  isInInteraction?: boolean;
};

export const usePDFControlling = ({
  initialPage = 1,
  isInInteraction = false,
}: UsePDFControllingProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [aspect, setAspect] = useState(ASPECT.contain);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [isPdfDocumentLoaded, setIsPdfDocumentLoaded] = useState(false);
  const [isPdfJsLoaded, setIsPdfJsLoaded] = useState(false);
  const { changePage } = useInteraction();

  const containerRef = useRef<HTMLDivElement>(null);
  const { height, width } = useElementSize(containerRef);

  useEffect(() => {
    const loadPdfJs = async () => {
      const { pdfjs } = await import('react-pdf');

      pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.mjs',
        import.meta.url
      ).toString();
      setIsPdfJsLoaded(true);
    };

    loadPdfJs();
  }, []);

  useEffect(() => {
    setAspect(isSmallScreen ? ASPECT.cover : ASPECT.contain);
  }, [isSmallScreen]);

  const getPDFSize = () => {
    // contain aspect fits the content based on its height
    // Make height of document = height of wrapper minus 2 to make the scroll not always show
    if (aspect === ASPECT.contain) {
      return { height: height - 2 };
    }

    // cover aspect fits the content based on its width
    return { width };
  };

  const handleLoadDocument = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setIsPdfDocumentLoaded(true);

    setTimeout(() => {
      // TODO: find a better way to properly fit PDF contnent in its container
      // calls the resize event so the PDF takes proper width/height when the PDF loads
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const handleChangeAspect = () => {
    setAspect((prev) => {
      return prev === ASPECT.contain ? ASPECT.cover : ASPECT.contain;
    });

    setTimeout(() => {
      // calls the resize event so the PDF takes proper width/height when aspect changes
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const [startTimeInPage, setStartTimeInPage] = useState<Date>(new Date());
  const handleChangePage = (offset: number) => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + offset;

      if (newPage === 0 || newPage > numberOfPages) {
        return prevPage;
      }

      if (isInInteraction) {
        changePage(
          { indexh: newPage, indexv: 0 },
          { indexh: prevPage, indexv: 0 },
          new Date().getTime() - startTimeInPage?.getTime()
        );

        setStartTimeInPage(new Date());
      }

      return newPage;
    });
  };

  return {
    aspect,
    handleChangeAspect,
    handleChangePage,
    numberOfPages,
    setCurrentPage,
    currentPage,
    handleLoadDocument,
    getPDFSize,
    containerRef,
    isPdfDocumentLoaded,
    isPdfJsLoaded,
  };
};
