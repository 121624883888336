import { Box, styled } from '@mui/material';
import type { Pointer } from '@ysura/common';
import { PointerAnimate } from '@ysura/common';
import {
  MouseEvent,
  PropsWithChildren,
  RefObject,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useInteraction } from '@/hooks';

const isTouchEvent = (e: TouchEvent | MouseEvent): e is TouchEvent => {
  return e && 'touches' in e;
};

const isMouseEvent = (e: TouchEvent | MouseEvent): e is MouseEvent => {
  return e && 'screenX' in e;
};

type PointerContainerProps = {
  iframeRef?: RefObject<HTMLIFrameElement>;
};

export const PointerContainer = ({
  children,
  iframeRef,
}: PropsWithChildren<PointerContainerProps>) => {
  const { onPointerShown, broadcastPointerShow } = useInteraction();
  const overlay = useRef<HTMLDivElement>();
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(
    null
  );
  const [pointers, setPointers] = useState<Array<Pointer>>([]);

  const showPointer = useCallback((pointerEvent: Pointer) => {
    setPointers((prevState) => [...prevState, pointerEvent]);
  }, []);

  const calculatePointer = (event: Pointer) => {
    // Map the position of the pointer in screen coords from percentage of the viewport width/height
    const boundingRect = overlay.current?.getBoundingClientRect();
    const pointerEvent = {
      id: event.id,
      posX: boundingRect
        ? event.rx * boundingRect.width + boundingRect.left
        : 0,
      posY: boundingRect
        ? event.ry * boundingRect.height + boundingRect.top
        : 0,
      isOrganizer: event.isOrganizer,
    };

    return pointerEvent;
  };

  const getCoordinates = (event: MouseEvent | TouchEvent) => {
    if (isTouchEvent(event)) {
      if (event.touches || event.changedTouches) {
        const touch = event.touches[0] || event.changedTouches[0];

        return { x: touch.clientX, y: touch.clientY };
      }
    } else if (isMouseEvent(event)) {
      return { x: event.clientX, y: event.clientY };
    }

    return { x: 0, y: 0 };
  };

  const handleMouseDown = (event: MouseEvent | TouchEvent) => {
    const { x, y } = getCoordinates(event);
    // Map the position of the pointer in screen coords in percentage of the viewport width/height
    const boundingRect = overlay.current?.getBoundingClientRect();

    if (boundingRect) {
      const mx = x - boundingRect.left;
      const my = y - boundingRect.top;
      const pointerEvent = {
        id: uuidv4(),
        posX: x,
        posY: y,
        rx: mx / boundingRect.width,
        ry: my / boundingRect.height,
        isOrganizer: true,
        source: 'overlay' as const,
      };

      const timeout = setTimeout(() => {
        showPointer(pointerEvent);
        broadcastPointerShow(pointerEvent);
      }, 200);
      setTimeoutHandler(timeout);
    }
  };

  const handleMouseUp = () => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
  };

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent) => {
      try {
        const data =
          event.data &&
          typeof event.data === 'string' &&
          event.data.indexOf('pointer') > 0 &&
          JSON.parse(event.data);

        if (data) {
          const boundingRect = overlay.current?.getBoundingClientRect();

          if (boundingRect) {
            if (data.eventName === 'pointerRequested') {
              const pointerEvent = {
                id: uuidv4(),
                posX: data.pointerRequest.posX + boundingRect.left,
                posY: data.pointerRequest.posY + boundingRect.top,
                rx: data.pointerRequest.rx,
                ry: data.pointerRequest.ry,
                isOrganizer: true,
                source: 'iframe' as const,
              };
              showPointer(pointerEvent);
              broadcastPointerShow(pointerEvent);
            }

            if (data.eventName === 'pointerCalculated') {
              const pointerEvent = {
                id: data.pointerCalculation.id,
                posX: data.pointerCalculation.posX + boundingRect.left,
                posY: data.pointerCalculation.posY + boundingRect.top,
                rx: data.pointerCalculation.rx,
                ry: data.pointerCalculation.ry,
                isOrganizer: data.pointerCalculation.isOrganizer,
                source: 'iframe',
              };
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              showPointer(pointerEvent);
            }
          }
        }
      } catch (e) {
        // TODO: Handle error
        console.error(e);
      }
    };
    window.addEventListener('message', handleWindowMessage);

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [broadcastPointerShow, showPointer]);

  useEffect(() => {
    const handleRemotePointerShown = (event: Pointer) => {
      if (event.source) {
        if (event.source === 'overlay') {
          const pointerEvent = calculatePointer(event);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          showPointer(pointerEvent);
        }
        if (event.source === 'iframe') {
          const msg = {
            namespace: 'reveal',
            eventName: 'pointer',
            pointer: event,
          };
          iframeRef?.current?.contentWindow?.postMessage(msg, '*');
        }
      }
    };
    const unsubscribe = onPointerShown(handleRemotePointerShown);

    return () => {
      unsubscribe?.();
    };
  }, [showPointer, iframeRef, onPointerShown]);

  const handlePointerComplete = (id: string) => {
    setPointers((prevState) => prevState.filter((item) => item.id !== id));
  };

  return (
    <Wrapper
      ref={overlay}
      data-testid="pointer-overlay"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      {pointers.map((point) => (
        <PointerAnimate
          key={point.id}
          pointer={point}
          onAnimationComplete={() => handlePointerComplete(point.id)}
        />
      ))}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'grid',
  height: '100%',
  width: '100%',
});
