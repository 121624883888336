import './i18n/config';
import './Sentry';

import { ThemeProvider } from '@ysura/common';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from '@/App';
import { KeycloakProvider } from '@/auth';
import { NotistackProvider } from '@/components/Notification';
import { API } from '@/config';
import { NotificationProvider } from '@/hooks';

// We only enable service worker API mocks when explicitly enabled
function prepareServiceWorker() {
  if (API.MSW_ENABLED) {
    const startWorker = async () => {
      const { worker, workerOptions } = await import('./mocks/browser');

      return worker.start(workerOptions);
    };

    return startWorker();
  }

  return Promise.resolve();
}

// We wait for the service worker to start before rendering the application
prepareServiceWorker().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <KeycloakProvider>
      <StrictMode>
        <ThemeProvider>
          <BrowserRouter>
            <NotistackProvider>
              <NotificationProvider>
                <App />
              </NotificationProvider>
            </NotistackProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StrictMode>
    </KeycloakProvider>
  );
});
