import {
  ActivityData,
  Callback,
  ConsentEventReplayParams,
  ConsentValue,
  ConsentViewerClosePayload,
  ConsentViewerPayload,
  DataCallback,
  HeartbeatCallbackParams,
  Media,
  MediaEventReplayParams,
  OrganizerPresentationStateChangePayload,
  Page,
  Participant,
  Pointer,
  PresentationStateChange,
  RejectConsentPayload,
  RevokeConsentPayload,
  SampleRequestEventReplayParams,
  SampleRequestViewerPayload,
  ScreenSharingEventReplayParams,
  SupportedMediaTypeDataEnum,
} from '@ysura/common';
import { useCallback } from 'react';

import { useCurrentUser } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { useAddMediaTrackingInfoMutation } from '@/services/graphql/hooks';
import { getPersonFullNameForInteraction } from '@/services/helper';

import { buildCurrentDateTime } from './helper';
import {
  InteractionStateContextProvider,
  useInteractionState,
} from './useInteractionState';
import {
  ConsentBeingShared,
  InitializeStateServerArgs,
  MediaBeingShared,
  MediaSharedForCoOrganizer,
  PersitantInteractionState,
  PersitantInteractionStateSetter,
  SetState,
  UseVideoState,
} from './useInteractionTypes';
import {
  ProcessStateContextProvider,
  StartSharingConsentProps,
  useProcessState,
} from './useProcessState';
import { StateServerContextProvider, useStateServer } from './useStateServer';
import { useVideoState, VideoStateContextProvider } from './useVideoState';

export type UseInteraction = PersitantInteractionState &
  Pick<
    UseVideoState,
    | 'isUserMediaCollected'
    | 'setIsUserMediaCollected'
    | 'isCameraActive'
    | 'isCameraAccessDisabled'
    | 'setIsCameraAccessDisabled'
    | 'isMicActive'
    | 'isMicAccessDisabled'
    | 'setIsMicAccessDisabled'
    | 'setSelectedAudioInput'
    | 'setSelectedVideoInput'
    | 'selectedVideoInput'
    | 'selectedAudioInput'
    | 'audioDevices'
    | 'setAudioDevices'
    | 'videoDevices'
    | 'setVideoDevices'
    | 'initializeVideo'
    | 'participantDeviceState'
    | 'participantVideoStatus'
  > & {
    // Management
    initializeInteraction: (activity: ActivityData) => void;
    initializeStateServer: (args: InitializeStateServerArgs) => void;
    joinInteractionFromWaitingRoom: VoidFunction;
    goToInteractionSummary: VoidFunction;
    organizerCloseSession: VoidFunction;
    leaveInteraction: VoidFunction;
    removeAttendeeFromInteraction: VoidFunction;
    onOrganizerTerminateSession: (callback: Callback) => Callback;
    getParticipantInteractionStep: (
      participantId: string
    ) => 'waiting-room' | 'interaction' | undefined;
    isStateServerInitialized: boolean;
    isAnyContentShared: boolean;
    totalPeopleInRoom: number;
    heartbeat: VoidFunction;

    // Video
    toggleIsCameraActive: VoidFunction;
    toggleIsMicActive: VoidFunction;

    // State
    setMediaUpdatingKeyMessages: PersitantInteractionStateSetter['setMediaUpdatingKeyMessages'];
    setTopics: PersitantInteractionStateSetter['setTopics'];
    setComments: PersitantInteractionStateSetter['setComments'];
    setConsentAsCollected: PersitantInteractionStateSetter['setConsentAsCollected'];
    setReactionForKeyMessage: PersitantInteractionStateSetter['setReactionForKeyMessage'];
    setMediaAsViewed: PersitantInteractionStateSetter['setMediaAsViewed'];
    setSignedSampleRequestForm: PersitantInteractionStateSetter['setSignedSampleRequestForm'];
    setPromotionalMaterialGroups: PersitantInteractionStateSetter['setPromotionalMaterialGroups'];

    // Media
    startSharingMedia: (mediaBeingShared: MediaBeingShared) => void;
    stopSharingMedia: () => void;
    changePage: (
      nextPage: Page,
      currentPage: Page,
      durationInPage: number
    ) => void;
    broadcastHTMLEvent: (stateChange: PresentationStateChange) => void;
    mediaSharedByOrganizer: MediaBeingShared | null;
    mediaSharedForCoOrganizer: MediaSharedForCoOrganizer | null;
    setMediaSharedForCoOrganizer: SetState<MediaSharedForCoOrganizer | null>;
    onMediaOpened: (callback: DataCallback<Media>) => Callback;
    onMediaClosed: (callback: Callback) => Callback;
    onMediaStateChanged: (
      callback: DataCallback<OrganizerPresentationStateChangePayload>
    ) => Callback;
    onPointerShown: (callback: DataCallback<Pointer>) => Callback;
    broadcastPointerShow: (event: Pointer) => void;
    isSharingInFullScreen: boolean;
    toggleIsSharingInFullscreen: VoidFunction;

    // Consent
    startSharingConsent: (props: StartSharingConsentProps) => void;
    stopSharingConsent: VoidFunction;
    changeSharingConsent: (payload: ConsentViewerPayload) => void;
    consentSharedByOrganizer: ConsentBeingShared | null;
    setCurrentConsentValues: (values: ConsentValue) => void;
    setCurrentConsentSignature: (signature: string) => void;
    setCurrentConsentPreview: (preview: string) => void;
    setCurrentConsentStep: (step: 'collect' | 'preview') => void;
    consentSharedForCoOrganizer: ConsentViewerPayload | null;
    setConsentSharedForCoOrganizer: SetState<ConsentViewerPayload | null>;
    rejectSharedConsent: (payload: RejectConsentPayload) => void;
    revokeSharedConsent: (payload: RevokeConsentPayload) => void;
    onConsentFormValueChanged: (
      callback: DataCallback<ConsentViewerPayload>
    ) => Callback;
    onConsentFormAttendeeSubmitted: (
      callback: DataCallback<ConsentViewerPayload>
    ) => Callback;
    onConsentFormOpened: (
      callback: DataCallback<ConsentViewerPayload>
    ) => Callback;
    onConsentClosed: (callback: Callback) => Callback;
    onRejectConsentAttendeeConfirmed: (
      callback: DataCallback<RejectConsentPayload>
    ) => Callback;
    onRevokeConsentAttendeeConfirmed: (
      callback: DataCallback<RevokeConsentPayload>
    ) => Callback;
    broadcastConsentClose: (event: ConsentViewerClosePayload) => void;

    // ProMatCollection
    startSampleRequestCollection: (data: SampleRequestViewerPayload) => void;
    stopSampleRequestCollection: (id: string) => void;
    changeSampleRequestCollection: (
      payload: SampleRequestViewerPayload
    ) => void;
    onSampleRequestValueChanged: (
      callback: DataCallback<SampleRequestViewerPayload>
    ) => Callback;
    onSampleRequestAttendeeSubmitted: (
      callback: DataCallback<SampleRequestViewerPayload>
    ) => Callback;
    onSampleRequestOpened: (
      callback: DataCallback<SampleRequestViewerPayload>
    ) => Callback;
    onSampleRequestClosed: (callback: Callback) => Callback;
    currentSampleRequest: SampleRequestViewerPayload | null;
    setCurrentSampleRequest: SetState<SampleRequestViewerPayload | null>;

    // Screen Sharing
    startScreenSharing: (
      shouldBroadcast?: boolean,
      participantSharingId?: string
    ) => void;
    onScreenShareStarted: () => Callback;
    stopScreenSharing: VoidFunction;
    isOwnScreenShared: boolean;
    isParticipantsScreenShared: boolean;
  };

export const useInteraction = (): UseInteraction => {
  const {
    // meta functions
    startMediaTracking,
    resetMediaTracking,
    clearInteractionState,
    startInteraction,
    trackPageChange,
    // persistent state
    originalActivity,
    interactionStep,
    interactionMode,
    currentRole,
    startDateTime,
    viewedMedia,
    promotionalMaterialGroups,
    signedSampleRequestForm,
    comments,
    topics,
    startTimeForMediaTracking,
    mediaTrackingInfo,
    collectedConsent,
    currentPageSharingMedia,
    reactions,
    mediaSharingSequence,
    // persistent state setters
    setMediaUpdatingKeyMessages,
    setTopics,
    setMediaTrackingInViewedMedia,
    setCurrentRole,
    setInteractionStep,
    setComments,
    setConsentAsCollected,
    setReactionForKeyMessage,
    setMediaAsViewed,
    setSignedSampleRequestForm,
    setPromotionalMaterialGroups,
    isDisconnecting,
    setIsDisconnecting,
  } = useInteractionState();
  const {
    broadcastPageChange,
    broadcastHTMLEvent,
    isAnyProcessShared,
    mediaSharedByOrganizer,
    setMediaSharedByOrganizer,
    mediaSharedForCoOrganizer,
    setMediaSharedForCoOrganizer,
    isSharingInFullScreen,
    setIsSharingInFullScreen,
    startSharingConsent,
    stopSharingConsent,
    changeSharingConsent,
    consentSharedByOrganizer,
    setConsentSharedByOrganizer,
    setCurrentConsentValues,
    setCurrentConsentSignature,
    setCurrentConsentPreview,
    setCurrentConsentStep,
    rejectSharedConsent,
    revokeSharedConsent,
    consentSharedForCoOrganizer,
    setConsentSharedForCoOrganizer,
    startSampleRequestCollection,
    stopSampleRequestCollection,
    changeSampleRequestCollection,
    currentSampleRequest,
    setCurrentSampleRequest,
  } = useProcessState();
  const {
    broadcastOrganizerMediaOpen,
    broadcastOrganizerMediaClose,
    onMediaClosed,
    onMediaOpened,
    onMediaStateChanged,
    onConsentFormValueChanged,
    onConsentFormAttendeeSubmitted,
    onConsentFormOpened,
    onConsentClosed,
    onRejectConsentAttendeeConfirmed,
    onRevokeConsentAttendeeConfirmed,
    broadcastConsentClose,
    onSampleRequestAttendeeSubmitted,
    onSampleRequestValueChanged,
    onSampleRequestOpened,
    onSampleRequestClosed,
    initChannel,
    doHeartbeat,
    isStateServerInitialized,
    broadcastWaitingRoomToInteraction,
    roomInfo,
    setRoomInfo,
    broadcastDisconnectClient,
    onPointerShown,
    broadcastPointerShow,
    totalPeopleInRoom,
    closeStateServerConnection,
    onOrganizerTerminateSession,
    broadcastOrganizerRoomClose,
    onScreenShareStarted: onScreenShareStartedInternal,
  } = useStateServer();
  const {
    isParticipantsScreenShared,
    handleVideoTokenGenerated,
    leaveVideoCall,
    startScreenSharing: startScreenSharingInternal,
    handleNotifyScreenShareStarted,
    stopScreenSharing,
    isOwnScreenShared,
    participantDeviceState,
    participantVideoStatus,
    isUserMediaCollected,
    setIsUserMediaCollected,
    isCameraActive,
    toggleIsCameraActive,
    isCameraAccessDisabled,
    isMicActive,
    toggleIsMicActive,
    isMicAccessDisabled,
    setIsCameraAccessDisabled,
    setIsMicAccessDisabled,
    setSelectedAudioInput,
    setSelectedVideoInput,
    selectedVideoInput,
    selectedAudioInput,
    audioDevices,
    setAudioDevices,
    videoDevices,
    setVideoDevices,
    initializeVideo: initializeVideoInternal,
  } = useVideoState();

  const { currentUser } = useCurrentUser();

  const [addMediaTrackingInfoMutation] = useAddMediaTrackingInfoMutation();

  const toggleIsSharingInFullscreen = useCallback(() => {
    setIsSharingInFullScreen((current) => !current);
  }, [setIsSharingInFullScreen]);

  /*****************************
   * Screen Sharing            *
   *****************************/
  const onScreenShareStarted = useCallback(() => {
    return onScreenShareStartedInternal(handleNotifyScreenShareStarted);
  }, [handleNotifyScreenShareStarted, onScreenShareStartedInternal]);

  const startScreenSharing = useCallback(
    (shouldBroadcast?: boolean, participantSharingId?: string) => {
      startScreenSharingInternal(shouldBroadcast, participantSharingId);
    },
    [startScreenSharingInternal]
  );

  /* *******************
   *  Media sharing    *
   ******************* */
  const startSharingMedia = useCallback(
    (mediaBeingShared: MediaBeingShared, isReconnecting: boolean = false) => {
      setMediaSharedByOrganizer(mediaBeingShared);

      let initialPage = mediaBeingShared.initialPage?.indexh;
      if (initialPage === undefined) {
        if (
          mediaBeingShared.media?.mediaType === SupportedMediaTypeDataEnum.HTML
        ) {
          initialPage = 0;
        } else {
          initialPage = 1;
        }
      }

      if (!isReconnecting) {
        startMediaTracking(initialPage);
      }

      broadcastOrganizerMediaOpen(mediaBeingShared.media);
    },
    [broadcastOrganizerMediaOpen, setMediaSharedByOrganizer, startMediaTracking]
  );

  const stopSharingMedia = useCallback(() => {
    if (!mediaSharedByOrganizer?.media) {
      return;
    }

    // stop tracking
    if (currentUser?.dataPermissions?.media?.canAddMediaTracking) {
      const audiencePersonId = originalActivity?.attendees?.[0]?.person?.oid;

      addMediaTrackingInfoMutation({
        ...errorConfigBannerDialog,
        variables: {
          mediaId: mediaSharedByOrganizer.media?.oid ?? '',
          mediaVersionId: mediaSharedByOrganizer.media.latestVersion?.oid ?? '',
          audiencePersonId,
          shownTime: startTimeForMediaTracking ?? buildCurrentDateTime(),
          data: mediaTrackingInfo ?? [],
        },
        onCompleted: ({ addMediaTrackingInfo }) => {
          if (addMediaTrackingInfo?.oid && mediaSharedByOrganizer.media) {
            setMediaTrackingInViewedMedia(mediaSharedByOrganizer.media, {
              id: addMediaTrackingInfo?.oid,
            });
          }

          resetMediaTracking();
        },
      });
    }

    broadcastOrganizerMediaClose(mediaSharedByOrganizer.media);
    setMediaSharedByOrganizer(null);
    setIsSharingInFullScreen(false);
  }, [
    addMediaTrackingInfoMutation,
    broadcastOrganizerMediaClose,
    currentUser?.dataPermissions?.media?.canAddMediaTracking,
    mediaSharedByOrganizer?.media,
    mediaTrackingInfo,
    originalActivity?.attendees,
    resetMediaTracking,
    setIsSharingInFullScreen,
    setMediaSharedByOrganizer,
    setMediaTrackingInViewedMedia,
    startTimeForMediaTracking,
  ]);
  const changePage = useCallback(
    (nextPage: Page, currentPage: Page, durationInPage: number) => {
      broadcastPageChange(nextPage);

      if (interactionStep === 'interaction') {
        trackPageChange(
          nextPage.indexh + 1,
          currentPage.indexh,
          durationInPage
        );
      }
    },
    [broadcastPageChange, interactionStep, trackPageChange]
  );

  /****************************
   * Event Replay             *
   ****************************/

  const handleMediaEventReplay = useCallback(
    (mediaReplay: MediaEventReplayParams) => {
      if (currentRole === 'organizer') {
        startSharingMedia(mediaReplay, true);
      } else {
        const media: Media = {
          contentDocument: {
            downloadUrl:
              mediaReplay.media.latestVersion?.document?.contentLink ?? '',
            mediaType:
              mediaReplay.media.latestVersion?.document?.mediaType ?? '',
          },
        };
        setMediaSharedForCoOrganizer({
          media,
          initialPage: mediaReplay.initialPage,
        });
      }
    },
    [currentRole, setMediaSharedForCoOrganizer, startSharingMedia]
  );

  const handleConsentEventReplay = useCallback(
    ({ state }: ConsentEventReplayParams) => {
      if (currentRole === 'organizer') {
        setConsentSharedByOrganizer({
          consentType: state.consentType,
          currentConsentValues:
            state.state.step === 'collect'
              ? state.state.consentItems
              : undefined,
          currentSignature:
            state.state.step === 'collect' ? state.state.signature : undefined,
          currentPreview:
            state.state.step === 'preview' ? state.state.content : '',
          currentStep: state.state.step,
          initialState: state,
        });
      } else {
        setConsentSharedForCoOrganizer(state);
      }
    },
    [currentRole, setConsentSharedByOrganizer, setConsentSharedForCoOrganizer]
  );

  const handleScreenSharingEventReplay = useCallback(
    ({ state }: ScreenSharingEventReplayParams) => {
      console.log('Screen sharing event replay', state);
      const currentPersonOid = currentUser?.person?.oid;
      if (currentPersonOid && state.participantSharingId != currentPersonOid) {
        handleNotifyScreenShareStarted();
      }
    },
    [currentUser?.person?.oid, handleNotifyScreenShareStarted]
  );

  const handleSampleRequestEventReplay = useCallback(
    ({ state }: SampleRequestEventReplayParams) => {
      setCurrentSampleRequest(state);
    },
    [setCurrentSampleRequest]
  );

  /*********************
   * Management        *
   *********************/

  const initializeInteraction = useCallback(
    (activity: ActivityData) => {
      startInteraction(activity);
    },
    [startInteraction]
  );

  const initializeStateServer = useCallback(
    ({ roomId, role, interactionStep }: InitializeStateServerArgs) => {
      if (isDisconnecting) {
        return;
      }

      setCurrentRole(role);

      if (interactionMode === 'remote') {
        initChannel({
          roomId,
          role,
          interactionStep,
          hasVideoConnection: true,
          onTokenCreated: handleVideoTokenGenerated,
          onMediaEventReplay: handleMediaEventReplay,
          onConsentEventReplay: handleConsentEventReplay,
          onSampleRequestEventReplay: handleSampleRequestEventReplay,
        });
      } else if (interactionMode === 'phone') {
        initChannel({
          roomId,
          role,
          interactionStep,
          hasVideoConnection: false,
          onMediaEventReplay: handleMediaEventReplay,
          onConsentEventReplay: handleConsentEventReplay,
          onScreenSharingEventReplay: handleScreenSharingEventReplay,
          onSampleRequestEventReplay: handleSampleRequestEventReplay,
        });
      } else {
        console.warn(
          `State server cannot be used in this activity type: ${interactionMode}`
        );
      }
    },
    [
      handleConsentEventReplay,
      handleMediaEventReplay,
      handleSampleRequestEventReplay,
      handleScreenSharingEventReplay,
      handleVideoTokenGenerated,
      initChannel,
      interactionMode,
      isDisconnecting,
      setCurrentRole,
    ]
  );

  const initializeVideo = useCallback(() => {
    if (!isDisconnecting) {
      initializeVideoInternal();
    }
  }, [initializeVideoInternal, isDisconnecting]);

  const joinInteractionFromWaitingRoom = useCallback(() => {
    if (isStateServerInitialized && interactionStep === 'waiting-room') {
      setInteractionStep('interaction');

      broadcastWaitingRoomToInteraction();
    }
  }, [
    broadcastWaitingRoomToInteraction,
    interactionStep,
    isStateServerInitialized,
    setInteractionStep,
  ]);

  const heartbeatCallback = useCallback(
    ({ room }: HeartbeatCallbackParams) => {
      if (room) {
        setRoomInfo(room);
      }
    },
    [setRoomInfo]
  );

  const heartbeat = useCallback(() => {
    if (
      isStateServerInitialized &&
      currentUser?.person?.oid &&
      currentRole &&
      (interactionStep === 'interaction' || interactionStep === 'waiting-room')
    ) {
      const participant: Participant = {
        id: currentUser?.person?.oid,
        displayName: getPersonFullNameForInteraction(currentUser?.person),
        role: currentRole,
        step: interactionStep,
      };

      doHeartbeat(participant, heartbeatCallback);
    }
  }, [
    currentRole,
    currentUser?.person,
    doHeartbeat,
    heartbeatCallback,
    interactionStep,
    isStateServerInitialized,
  ]);

  const disconnectRemoteConnections = useCallback(() => {
    if (interactionMode === 'remote') {
      leaveVideoCall();
    }

    if (isStateServerInitialized) {
      closeStateServerConnection();
    }
  }, [
    closeStateServerConnection,
    interactionMode,
    leaveVideoCall,
    isStateServerInitialized,
  ]);

  const organizerCloseSession = useCallback(() => {
    if (currentRole !== 'organizer') {
      return;
    }

    setIsDisconnecting(true);
    broadcastOrganizerRoomClose();
    disconnectRemoteConnections();
  }, [
    broadcastOrganizerRoomClose,
    currentRole,
    disconnectRemoteConnections,
    setIsDisconnecting,
  ]);

  const goToInteractionSummary = useCallback(() => {
    if (interactionStep === 'interaction') {
      setInteractionStep('summary');
    }
  }, [interactionStep, setInteractionStep]);

  const leaveInteraction = useCallback(() => {
    setIsDisconnecting(true);
    clearInteractionState();
    setIsSharingInFullScreen(false);
    disconnectRemoteConnections();
  }, [
    clearInteractionState,
    disconnectRemoteConnections,
    setIsDisconnecting,
    setIsSharingInFullScreen,
  ]);

  const removeAttendeeFromInteraction = useCallback(() => {
    broadcastDisconnectClient();
  }, [broadcastDisconnectClient]);

  const getParticipantInteractionStep = useCallback(
    (participantId: string) => {
      return roomInfo?.participants?.[participantId]?.step;
    },
    [roomInfo?.participants]
  );

  return {
    // Management
    initializeInteraction,
    initializeStateServer,
    joinInteractionFromWaitingRoom,
    goToInteractionSummary,
    organizerCloseSession,
    leaveInteraction,
    removeAttendeeFromInteraction,
    onOrganizerTerminateSession,
    getParticipantInteractionStep,
    isStateServerInitialized,
    isAnyContentShared: isAnyProcessShared || isParticipantsScreenShared,
    totalPeopleInRoom,
    heartbeat,
    // Video
    participantDeviceState,
    participantVideoStatus,
    isUserMediaCollected,
    setIsUserMediaCollected,
    isCameraActive,
    toggleIsCameraActive,
    isCameraAccessDisabled,
    isMicActive,
    toggleIsMicActive,
    isMicAccessDisabled,
    setIsCameraAccessDisabled,
    setIsMicAccessDisabled,
    setSelectedAudioInput,
    setSelectedVideoInput,
    selectedVideoInput,
    selectedAudioInput,
    audioDevices,
    setAudioDevices,
    videoDevices,
    setVideoDevices,
    initializeVideo,
    // State
    originalActivity,
    startDateTime,
    promotionalMaterialGroups,
    viewedMedia,
    topics,
    comments,
    signedSampleRequestForm,
    collectedConsent,
    currentPageSharingMedia,
    reactions,
    mediaSharingSequence,
    interactionMode,
    interactionStep,
    currentRole,
    mediaTrackingInfo,
    startTimeForMediaTracking,
    setMediaUpdatingKeyMessages,
    setTopics,
    setComments,
    setConsentAsCollected,
    setReactionForKeyMessage,
    setMediaAsViewed,
    setSignedSampleRequestForm,
    setPromotionalMaterialGroups,
    // Processes
    // Media (all)
    isSharingInFullScreen,
    toggleIsSharingInFullscreen,
    mediaSharedByOrganizer,
    mediaSharedForCoOrganizer,
    setMediaSharedForCoOrganizer,
    // Media (Organizer)
    startSharingMedia,
    stopSharingMedia,
    changePage,
    broadcastHTMLEvent,
    onPointerShown,
    broadcastPointerShow,
    // Media (CoOrganizer)
    onMediaOpened,
    onMediaClosed,
    onMediaStateChanged,
    // Consent (all)
    onConsentFormValueChanged,
    onConsentFormAttendeeSubmitted,
    consentSharedByOrganizer,
    consentSharedForCoOrganizer,
    setConsentSharedForCoOrganizer,
    // Consent (Organizer)
    startSharingConsent,
    stopSharingConsent,
    changeSharingConsent,
    setCurrentConsentValues,
    setCurrentConsentSignature,
    setCurrentConsentPreview,
    setCurrentConsentStep,
    rejectSharedConsent,
    revokeSharedConsent,
    broadcastConsentClose,
    // Consent (CoOrganizer)
    onConsentFormOpened,
    onConsentClosed,
    onRejectConsentAttendeeConfirmed,
    onRevokeConsentAttendeeConfirmed,
    // ProMatCollection (all)
    onSampleRequestAttendeeSubmitted,
    onSampleRequestValueChanged,
    currentSampleRequest,
    setCurrentSampleRequest,
    // ProMatCollection (Organizer)
    startSampleRequestCollection,
    stopSampleRequestCollection,
    changeSampleRequestCollection,
    // ProMatCollection (CoOrganizer)
    onSampleRequestOpened,
    onSampleRequestClosed,
    // Screen Sharing
    startScreenSharing,
    onScreenShareStarted,
    stopScreenSharing,
    isOwnScreenShared,
    isParticipantsScreenShared,
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const InteractionContextProvider = ({ children }) => {
  return (
    <InteractionStateContextProvider>
      <StateServerContextProvider>
        <ProcessStateContextProvider>
          <VideoStateContextProvider>{children}</VideoStateContextProvider>
        </ProcessStateContextProvider>
      </StateServerContextProvider>
    </InteractionStateContextProvider>
  );
};
