import {
  ActivityData,
  ActivityTypeBaseTypeDataEnum,
  CommentData,
  MediaData,
  MediaReactionData,
  MediaTrackingInfoDataData,
  PromotionalMaterialGroupData,
  ReactionDataEnum,
  ReactProps,
  SampleRequestFormData,
  sortPromotionalMaterialGroup,
  TopicData,
  useLocalStorage,
} from '@ysura/common';
import { formatISO } from 'date-fns';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  getRequestedQuantity,
  groupGivenPromotionalMaterials,
} from '@/views/Activity/Update/PromotionalMaterials/helper';

import { buildCurrentDateTime } from './helper';
import {
  DEFAULT_FN,
  InteractionMode,
  MediaTrackingData,
  OrganizerParticipantRole,
  PersitantInteractionState,
  PersitantInteractionStateSetter,
} from './useInteractionTypes';

export type ReactionState = Record<string, MediaReactionData>;
export type SharedItem = {
  id: string;
};

type UseInteractionState = PersitantInteractionState &
  PersitantInteractionStateSetter & {
    // Meta functions
    startInteraction: (activity: ActivityData) => void;
    clearInteractionState: VoidFunction;
    trackPageChange: (
      currentPage: number,
      viewedPage: number,
      durationInPage: number
    ) => void;
    resetMediaTracking: VoidFunction;
    isDisconnecting: boolean;
    setIsDisconnecting: (isDisconnecting: boolean) => void;
  };

const persitantInteractionStateInitialValue: PersitantInteractionState = {
  originalActivity: null,
  interactionStep: 'waiting-room',
  currentRole: null,
  interactionMode: null,
  startDateTime: '',
  viewedMedia: {},
  promotionalMaterialGroups: [],
  signedSampleRequestForm: null,
  comments: [],
  reactions: {},
  collectedConsent: [],
  topics: [],
  startTimeForMediaTracking: null,
  currentPageSharingMedia: null,
  mediaTrackingInfo: null,
  mediaSharingSequence: null,
};

export const useInteractionStateInitialValue: UseInteractionState = {
  ...persitantInteractionStateInitialValue,

  startInteraction: DEFAULT_FN,
  clearInteractionState: DEFAULT_FN,
  trackPageChange: DEFAULT_FN,
  resetMediaTracking: DEFAULT_FN,
  isDisconnecting: false,
  setIsDisconnecting: DEFAULT_FN,
  setInteractionStep: DEFAULT_FN,
  setCurrentRole: DEFAULT_FN,
  setMediaAsViewed: DEFAULT_FN,
  setMediaTrackingInViewedMedia: DEFAULT_FN,
  startMediaTracking: DEFAULT_FN,
  setMediaUpdatingKeyMessages: DEFAULT_FN,
  setConsentAsCollected: DEFAULT_FN,
  setSignedSampleRequestForm: DEFAULT_FN,
  setComments: DEFAULT_FN,
  setReactionForMedia: DEFAULT_FN,
  setReactionForKeyMessage: DEFAULT_FN,
  setPromotionalMaterialGroups: DEFAULT_FN,
  setTopics: DEFAULT_FN,
};

const InteractionStateContext = createContext(useInteractionStateInitialValue);

export const InteractionStateContextProvider: FC<ReactProps> = (props) => {
  const [persitantInteractionState, setPersitantInteractionState] =
    useLocalStorage<PersitantInteractionState>(
      'persitantInteractionState',
      persitantInteractionStateInitialValue
    );

  const [isInteractionStateInitialized, setIsInteractionStateInitialized] =
    useState(false);

  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const clearInteractionState = useCallback(() => {
    setPersitantInteractionState(persitantInteractionStateInitialValue);
    setIsInteractionStateInitialized(false);
  }, [setPersitantInteractionState]);

  // Clear state if
  // - startDateTime is more than 24 hours ago or
  // - we are not in a URL path that starts with /interaction.
  // This is supposed to catch cases where the user did not end the interaction properly.
  useEffect(() => {
    if (
      persitantInteractionState.originalActivity &&
      persitantInteractionState.startDateTime
    ) {
      // if startDateTime is more than 24 hours ago
      const oneDay = 24 * 60 * 60 * 1000;
      const now = new Date();
      const isOlderThanOneDay =
        now.getTime() -
          new Date(persitantInteractionState.startDateTime).getTime() >
        oneDay;

      if (isOlderThanOneDay) {
        clearInteractionState();
      }
    }
  }, [
    clearInteractionState,
    persitantInteractionState.originalActivity,
    persitantInteractionState.startDateTime,
  ]);

  // Write to local storage
  const setOriginalActivity = useCallback(
    (activity: ActivityData | null) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        originalActivity: activity,
      }));
    },
    [setPersitantInteractionState]
  );

  const setInteractionStep = useCallback(
    (interactionStep: PersitantInteractionState['interactionStep']) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        interactionStep,
      }));
    },
    [setPersitantInteractionState]
  );

  const setCurrentRole = useCallback(
    (currentRole: OrganizerParticipantRole) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        currentRole,
      }));
    },
    [setPersitantInteractionState]
  );

  const setInteractionMode = useCallback(
    (interactionMode: InteractionMode) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        interactionMode,
      }));
    },
    [setPersitantInteractionState]
  );

  const setStartDateTime = useCallback(
    (startDateTime: string) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        startDateTime,
      }));
    },
    [setPersitantInteractionState]
  );

  const setPromotionalMaterialGroups = useCallback(
    (promotionalMaterialGroups: Array<PromotionalMaterialGroupData>) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        promotionalMaterialGroups,
      }));
    },
    [setPersitantInteractionState]
  );

  const setSignedSampleRequestForm = useCallback(
    (requestForm: SampleRequestFormData | null) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        signedSampleRequestForm: requestForm,
      }));
    },
    [setPersitantInteractionState]
  );

  const setComments = useCallback(
    (comments: Array<CommentData>) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        comments,
      }));
    },
    [setPersitantInteractionState]
  );

  const setTopics = useCallback(
    (topics: Array<TopicData>) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        topics,
      }));
    },
    [setPersitantInteractionState]
  );

  const setStartTimeForMediaTracking = useCallback(
    (reset?: boolean) => {
      const startTime = reset ? null : buildCurrentDateTime();

      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        startTimeForMediaTracking: startTime,
      }));
    },
    [setPersitantInteractionState]
  );

  const setCurrentPageSharingMedia = useCallback(
    (currentPageSharingMedia: number | null) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        currentPageSharingMedia,
      }));
    },
    [setPersitantInteractionState]
  );

  const setMediaTrackingInfoData = useCallback(
    (mediaTrackingInfoData: MediaTrackingInfoDataData | null) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        mediaTrackingInfo: mediaTrackingInfoData
          ? [
              ...(currentPersitantInteractionState?.mediaTrackingInfo ?? []),
              mediaTrackingInfoData,
            ]
          : null,
      }));
    },
    [setPersitantInteractionState]
  );

  const setMediaSharingSequence = useCallback(
    (mediaSharingSequence: number | null) => {
      setPersitantInteractionState((currentPersitantInteractionState) => ({
        ...currentPersitantInteractionState,
        mediaSharingSequence,
      }));
    },
    [setPersitantInteractionState]
  );

  const startMediaTracking = useCallback(
    (initialPage: number | undefined) => {
      setStartTimeForMediaTracking();
      setCurrentPageSharingMedia(initialPage ?? 1);
      setMediaSequence(persitantInteractionState.mediaSharingSequence ?? 0);
    },
    [
      persitantInteractionState.mediaSharingSequence,
      setCurrentPageSharingMedia,
      setStartTimeForMediaTracking,
    ]
  );

  // Meta-Functions
  const startInteraction = useCallback(
    (activity: ActivityData) => {
      if (isInteractionStateInitialized) {
        return;
      }

      // reset existing state
      setPersitantInteractionState(persitantInteractionStateInitialValue);

      // set new state
      setOriginalActivity(activity);
      setStartDateTime(formatISO(new Date()));
      setTopics(activity?.topics ?? []);
      setComments([...(activity?.comments ?? [])]);

      const groupedPromotionalMaterials = groupGivenPromotionalMaterials(
        activity?.promotionalMaterials ?? []
      );
      const transformedPromotionalMaterials = groupedPromotionalMaterials.map(
        (each) => {
          return {
            ...each,
            requestedQuantity: getRequestedQuantity(each),
            selected: true,
          };
        }
      );
      setPromotionalMaterialGroups(
        sortPromotionalMaterialGroup(transformedPromotionalMaterials)
      );

      if (
        activity?.activityType?.baseType ===
        ActivityTypeBaseTypeDataEnum.DIRECT_CONTACT
      ) {
        // set default interact step status to 'interaction' when activity type is F2F
        setInteractionStep('interaction');
        setInteractionMode('f2f');
      } else if (
        activity?.activityType?.baseType === ActivityTypeBaseTypeDataEnum.REMOTE
      ) {
        setInteractionStep('waiting-room');
        setInteractionMode('remote');
      } else if (
        activity?.activityType?.baseType ===
        ActivityTypeBaseTypeDataEnum.TELEPHONE
      ) {
        setInteractionStep('waiting-room');
        setInteractionMode('phone');
      }

      setIsInteractionStateInitialized(true);
    },
    [
      isInteractionStateInitialized,
      setComments,
      setInteractionMode,
      setInteractionStep,
      setOriginalActivity,
      setPersitantInteractionState,
      setPromotionalMaterialGroups,
      setStartDateTime,
      setTopics,
    ]
  );

  const [mediaSequence, setMediaSequence] = useState<number>(0);

  const setMediaTrackingInViewedMedia = useCallback(
    (media: MediaData, mediaTracking: MediaTrackingData) => {
      if (media && mediaTracking) {
        setPersitantInteractionState((currentPersitantInteractionState) => {
          if (!media.id) {
            return currentPersitantInteractionState;
          }

          let currentViewedMedia = currentPersitantInteractionState.viewedMedia;

          currentViewedMedia = {
            ...currentViewedMedia,
            [media.id]: {
              ...currentViewedMedia[media.id],
              mediaTracking: [
                ...currentViewedMedia[media.id].mediaTracking,
                mediaTracking,
              ],
            },
          };

          return {
            ...currentPersitantInteractionState,
            viewedMedia: currentViewedMedia,
          };
        });
      }
    },
    [setPersitantInteractionState]
  );

  const setMediaUpdatingKeyMessages = useCallback(
    (medias: Array<MediaData>) => {
      setPersitantInteractionState((currentPersitantInteractionState) => {
        const currentViewedMedia = currentPersitantInteractionState.viewedMedia;

        medias.forEach((media) => {
          if (media.id) {
            const updatedMedia = {
              ...currentViewedMedia[media.id],
              keyMessages: media.keyMessages,
            };

            currentViewedMedia[media.id] = updatedMedia;
          }
        });

        return {
          ...currentPersitantInteractionState,
          viewedMedia: currentViewedMedia,
        };
      });
    },
    [setPersitantInteractionState]
  );

  const trackPageChange = useCallback(
    (currentPage: number, viewedPage: number, durationInPage: number) => {
      const trackingInfo: MediaTrackingInfoDataData = {
        sequence: mediaSequence,
        page: viewedPage,
        duration: durationInPage,
      };

      setMediaTrackingInfoData(trackingInfo);
      setMediaSequence((prevState) => prevState + 1);
      setMediaSharingSequence(mediaSequence + 1);
      setCurrentPageSharingMedia(currentPage);
    },
    [
      mediaSequence,
      setCurrentPageSharingMedia,
      setMediaSharingSequence,
      setMediaTrackingInfoData,
    ]
  );

  const resetMediaTracking = useCallback(() => {
    setStartTimeForMediaTracking(true);
    setCurrentPageSharingMedia(null);
    setMediaTrackingInfoData(null);
    setMediaSharingSequence(null);
  }, [
    setCurrentPageSharingMedia,
    setMediaSharingSequence,
    setMediaTrackingInfoData,
    setStartTimeForMediaTracking,
  ]);

  const setMediaAsViewed = useCallback(
    (media: MediaData) => {
      if (media) {
        setPersitantInteractionState((currentPersitantInteractionState) => {
          if (!media.id) {
            return currentPersitantInteractionState;
          }

          let currentViewedMedia = currentPersitantInteractionState.viewedMedia;

          // If media has already been tracked as viewed, we skip as we
          // can only have a media once in viewedMedia.
          if (currentViewedMedia[media.id]) {
            return currentPersitantInteractionState;
          }

          // Otherwise, we track the media as viewed
          currentViewedMedia = {
            ...currentViewedMedia,
            [media.id]: {
              media: {
                ...media,
                isViewed: 1,
              },
              mediaTracking: [],
            },
          };

          return {
            ...currentPersitantInteractionState,
            viewedMedia: currentViewedMedia,
          };
        });
      }
    },
    [setPersitantInteractionState]
  );

  const setConsentAsCollected = useCallback(
    (id: string) => {
      setPersitantInteractionState((currentPersitantInteractionState) => {
        let collectedConsent =
          currentPersitantInteractionState.collectedConsent;

        if (!collectedConsent.find((item) => item.id === id)) {
          collectedConsent = [...collectedConsent, { id }];
        }

        return {
          ...currentPersitantInteractionState,
          collectedConsent,
        };
      });
    },
    [setPersitantInteractionState]
  );

  const setReactionForMedia = useCallback(
    (mediaId: string, reaction: ReactionDataEnum) => {
      setPersitantInteractionState((currentPersitantInteractionState) => {
        const newReactions = { ...currentPersitantInteractionState.reactions };

        const mediaReaction: MediaReactionData = newReactions?.[mediaId] ?? {};

        newReactions[mediaId] = {
          ...mediaReaction,
          reaction,
        };

        return {
          ...currentPersitantInteractionState,
          reactions: newReactions,
        };
      });
    },
    [setPersitantInteractionState]
  );

  const setReactionForKeyMessage = useCallback(
    (mediaId: string, keyMessageId: string, reaction: ReactionDataEnum) => {
      setPersitantInteractionState((currentPersitantInteractionState) => {
        const newReactions = { ...currentPersitantInteractionState.reactions };

        const mediaEntry: MediaReactionData = newReactions?.[mediaId] ?? {};
        const keyMessagesForEntry = mediaEntry?.keyMessages ?? {};

        const updatedKeyMessages = {
          ...keyMessagesForEntry,
          [keyMessageId]: reaction,
        };

        newReactions[mediaId] = {
          ...mediaEntry,
          keyMessages: updatedKeyMessages as Record<string, ReactionDataEnum>,
        };

        return {
          ...currentPersitantInteractionState,
          reactions: newReactions,
        };
      });
    },
    [setPersitantInteractionState]
  );

  const values = useMemo(
    () => ({
      ...persitantInteractionState,

      startInteraction,
      clearInteractionState,

      trackPageChange,
      isDisconnecting,
      setIsDisconnecting,
      setSignedSampleRequestForm,
      setMediaAsViewed,
      setConsentAsCollected,
      setInteractionStep,
      setCurrentRole,
      setReactionForMedia,
      setReactionForKeyMessage,
      setPromotionalMaterialGroups,
      setTopics,
      setMediaUpdatingKeyMessages,
      setComments,
      startMediaTracking,
      resetMediaTracking,
      setCurrentPageSharingMedia,
      setMediaTrackingInViewedMedia,
      setMediaSharingSequence,
    }),
    [
      persitantInteractionState,
      startInteraction,
      clearInteractionState,
      trackPageChange,
      isDisconnecting,
      setSignedSampleRequestForm,
      setMediaAsViewed,
      setConsentAsCollected,
      setInteractionStep,
      setCurrentRole,
      setReactionForMedia,
      setReactionForKeyMessage,
      setPromotionalMaterialGroups,
      setTopics,
      setMediaUpdatingKeyMessages,
      setComments,
      startMediaTracking,
      resetMediaTracking,
      setCurrentPageSharingMedia,
      setMediaTrackingInViewedMedia,
      setMediaSharingSequence,
    ]
  );

  return <InteractionStateContext.Provider value={values} {...props} />;
};

export const useInteractionState = () => {
  return useContext(InteractionStateContext);
};
